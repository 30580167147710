import React, { useState } from "react";
// componentes
import BottomMenu from "./bottomMenu";
// imgs
import MapImage from "../../assets/images/vidCon/map.png";
import MapaTerreo from "../../assets/images/vidCon/plata.png";
import MapaMezanino from "../../assets/images/vidCon/mapa-mezanino.jpg";
export default function EventMap() {
  const [step, setStep] = useState("a");
  const [vertical1, setVertical1] = useState(true);
  const [vertical2, setVertical2] = useState(true);
  return (
    <div className="center-img">
      {step === "a" && (
        <img
          src={MapaTerreo}
          className={`img-map ${!vertical1 && "img-map-horizontal"}`}
          onClick={() => setVertical1(!vertical1)}
        />
      )}

      {step === "b" && (
        <img
          src={MapaMezanino}
          className={`img-map ${!vertical2 && "img-map-horizontal"}`}
          onClick={() => setVertical2(!vertical2)}
        />
      )}
      <BottomMenu step={step} setStep={setStep} />
    </div>
  );
}
