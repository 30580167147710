import React from "react";

export default function BottomMenu(props) {
  return (
    <div className="bottom-menu">
      <div
        className={`item-menu ${props.step === "a" && "item-menu-selected"}`}
        onClick={() => props.setStep("a")}
      >
        <i className="mdi mdi-map" style={{ fontSize: 30 }} />
        <a>Mapa Térreo</a>
      </div>

      <div
        className={`item-menu ${props.step === "b" && "item-menu-selected"}`}
        onClick={() => props.setStep("b")}
      >
        <i className="mdi mdi-home-map-marker" style={{ fontSize: 30 }} />
        <a>Mapa do Mezanino</a>
      </div>
    </div>
  );
}
