import React, { useState } from "react";
// components
import BottomMenu from "./bottomMenu";
import MenuPartyLounge from "./menuPartyLoung";
import MenuActivity from "./menuActivity/menuActivity";
import MenuFoodDrink from "./menuFoodDrink";

export default function ThingsToDo() {
  const [step, setStep] = useState("a");
  return (
    <section style={{ paddingBottom: 100 }}>
        {step === "a" && <MenuPartyLounge />}
        {step === "b" && <MenuActivity />}
        {step === "c" && <MenuFoodDrink />}
      <BottomMenu setStep={setStep} step={step} />
    </section>
  );
}
