import React from "react";

export default function BottomMenu(props) {
  return (
    <div className="bottom-menu">
      <div
        className={`item-menu ${props.step === 'a' && 'item-menu-selected'}`}
        onClick={() => props.setStep('a')}
      >
        <i className="mdi mdi-party-popper" style={{ fontSize: 30 }} />
        <a>Meet & Greet</a>
      </div>

      <div
        className={`item-menu ${props.step === 'b' && 'item-menu-selected'}`}
        onClick={() => props.setStep('b')}
      >
        <i
          className="mdi mdi-format-list-bulleted-square"
          style={{ fontSize: 30 }}
        />
        <a>Atividades</a>
      </div>

      <div
        className={`item-menu ${props.step === 'c' && 'item-menu-selected'}`}
        onClick={() => props.setStep('c')}
      >
        <i className="mdi mdi-food" style={{ fontSize: 30 }} />
        <a>Praça de Alimentação</a>
      </div>
    </div>
  );
}
