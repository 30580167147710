import React, { useState } from "react";

export default function QuestionFaq(props) {
  const [col, setCol] = useState(false);
  //   function t_col(i) {
  //     setCol(i);
  //   }
  return (
    <div key={props.i} className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button
          className={`accordion-button fw-medium ${!col && "collapsed"}`}
          type="button"
          onClick={() => {
            setCol(!col);
          }}
          style={{ cursor: "pointer" }}
        >
          {props.quest.title}
        </button>
      </h2>
      {props.quest.data(col)}
    </div>
  );
}
