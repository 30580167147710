import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from '@sentry/react';

import "./index.css";
import App from "./App";

Sentry.init({
  // dsn: 'https://f9cc6efa710643a2984401a4800d95ae@o4505359781593088.ingest.sentry.io/4505359782576128',
  dsn: 'https://dbddf20cc2254fc6be5c9d3106d3669f@o1192043.ingest.sentry.io/4505490332385280',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

