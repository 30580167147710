import React, { useState } from "react";
// img
import Inteegra from "../../../../assets/images/vidCon/inteegra.png";
// componentes
import ModalInfoSchedule from "./modalInfoSchedule";

export default function OptionWhereSchedule(props) {
  // dados
  const { data } = props;
  // modal
  const [modalInfo, setModalInfo] = useState(false);
  return (
    <div className="option-schedule" style={{ justifyContent: "center" }}>
      <ModalInfoSchedule
        isOpen={modalInfo}
        toggle={() => setModalInfo(!modalInfo)}
        data={modalInfo ? data : null}
        scheduleList={props.scheduleList}
      />
      <div
        style={{ width: "75%", display: "flex", alignItems: "center", justifyContent: "center" }}
        onClick={() => {
          setModalInfo(true);
        }}
      >
        {/* <img src={Inteegra} className="img-option-schedule" /> */}

        <a style={{ fontWeight: "bold" }}>{data.name}</a>
      </div>
    </div>
  );
}
