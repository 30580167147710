import React, { useState } from "react";
// componentes
import ModalInfoSchedule from "./modalInfoSchedule";

export default function Lecture(props) {
  const { data } = props;
  const [modal, setModal] = useState(false);
  return (
    <>
      <ModalInfoSchedule
        isOpen={modal}
        toggle={() => setModal(!modal)}
        data={data}
      />
      <div
        className="div-description-schedule"
        style={{ margin: "10px 0", justifyContent: "center" }}
        onClick={() => setModal(true)}
      >
        <div className="data-description-schedule">
          <a style={{ fontWeight: "bold", fontSize: 17, textAlign: "center" }}>
            {data.title}
          </a>
        </div>
      </div>
    </>
  );
}
