import React, { useState, useEffect } from "react";
// componentes
import ModalInfoMySchedule from "./modalInfoMySchedule";

export default function OptionMySchedule(props) {
  const [selected, setselected] = useState(false);
  // modal
  const [modalInfo, setModalInfo] = useState(false);

  function favoriteSchedule() {
    const scheduleSave = props.mySchedule.filter((e) => e.id === props.data.id);

    if (scheduleSave.length > 0) {
      var index = props.mySchedule.findIndex((e) => e.id === props.data.id);
      var newStateSchedule = props.mySchedule.filter((e, i) => i != index);
      props.setMySchedule(newStateSchedule);
    } else {
      props.setMySchedule((state) => {
        return [
          ...state,
          {
            title: props.title,
          },
        ];
      });
    }
  }
  return (
    <div className="option-schedule">
      <ModalInfoMySchedule
        isOpen={modalInfo}
        toggle={() => setModalInfo(!modalInfo)}
        date={props.data.date}
        title={props.data.title}
        logo={props.data.logo}
        local={props.data.local}
        startEnd={props.data.startEnd}
        description={props.data.description}
        instagram={props.data.instagram}
        twitter={props.data.twitter}
      />
      <div
        style={{ width: "75%", display: "flex", alignItems: "center" }}
        onClick={() => setModalInfo(true)}
      >
        <img
          style={{
            borderRadius: "50%",
            width: "65px",
            height: "65px",
            marginRight: "10px",
          }}
          src={props.data.logo}
        />

        <div className="data-option-schedule" style={{ paddingLeft: 10 }}>
          <a style={{ color: "blue", fontWeight: "bolder" }}>
            {props.data.startEnd}
          </a>
          <a
            style={{ fontWeight: "bold" }}
            // onClick={() => console.log(props.data)}
          >
            {props.title}
          </a>
          <a>
            <i className="mdi mdi-map-marker" />
            {props.data.local}
          </a>
        </div>
      </div>

      <div style={{ width: "25%", display: "flex", justifyContent: "center" }}>
        {/* <div> */}
        <div onClick={() => favoriteSchedule()}>
          <i
            className="mdi mdi-heart add-option-schedule"
            style={{ color: "red" }}
          />
        </div>
      </div>
    </div>
  );
}
