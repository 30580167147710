import React, { useState, useEffect } from "react";
// componente
import OptionWhenSchedule from "./optionWhenSchedule";

export default function Schedule09(props) {
  const [mySchedule, setMySchedule] = useState(
    localStorage.getItem("schedule09")
      ? JSON.parse(localStorage.getItem("schedule09"))
      : []
  );

  useEffect(() => {
    props.setSearch("");
  }, []);

  useEffect(() => {
    localStorage.setItem("schedule09", JSON.stringify(mySchedule));
  }, [mySchedule]);
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "5%" }}>
      {props.scheduleList &&
        props.scheduleList
          .filter(
            (e) =>
              e.start_time.substr(0, 10).split("-").reverse().join("-") ===
              "09-07-2023"
          )
          .filter((e) =>
            e.title.toLocaleLowerCase().includes(props.search.toLowerCase())
          )
          .map((e, i) => (
            <OptionWhenSchedule
              mySchedule={mySchedule}
              setMySchedule={setMySchedule}
              data={e}
              key={i}
            />
          ))}
    </div>
  );
}
