//styles
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/theme.scss";
import "./App.css";
// react router dom
import { BrowserRouter, Routes, Route } from "react-router-dom";
// componentes
import Sponsors from "./pages/sponsors";
import ThingsToDo from "./pages/thingsToDo";
import EventMap from "./pages/eventMap";
import EventLocation from "./pages/eventLocation";
import Schedule from "./pages/schedule";
import MySchedule from "./pages/mySchedule";
import Services from "./pages/services";
import Meeting from "./pages/meeting";
import SocialMedia from "./pages/socialMedia";
import RedirectStore from "./pages/redirectStore";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Sponsors />} />
          <Route path="/things-to-do" element={<ThingsToDo />} />
          <Route path="/event-map" element={<EventMap />} />
          <Route path="/event-location" element={<EventLocation />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/my-schedule" element={<MySchedule />} />
          <Route path="/services" element={<Services />} />
          <Route path="/meeting" element={<Meeting />} />
          <Route path="/social-media" element={<SocialMedia />} />
          <Route path="/store" element={<RedirectStore />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
