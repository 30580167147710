import React, { useState } from "react";
// componentes
import ModalInfo from "./modalInfo";

export default function OptionSpeaker(props) {
  const { i, item, scheduleList } = props;
  // modal
  const [modalInfo, setModalInfo] = useState(false);

  return (
    <>
      <ModalInfo
        isOpen={modalInfo}
        toggle={() => setModalInfo(!modalInfo)}
        data={item}
        scheduleList={scheduleList}
      />
      <div
        // key={i}
        className="div-contact"
        onClick={() => {
          setModalInfo(true);
          // console.log(scheduleList)
        }}
      >
        <img src={item.public_image_url} className="img-contact" />
        <a>{item.full_publication_name}</a>
      </div>
    </>
  );
}
