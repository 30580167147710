import React from 'react';
// componentes
import Sponsors from './sponsors';
import { sponsors } from '../../models';

export default function MenuSponsors() {
  return (
    <div className="list-itens-column">
      {sponsors.map((item) => (
        <Sponsors
          name={item.name}
          url={item.url}
          socialMedia={item.socialMedia}
          logo={item.logo}
        />
      ))}
    </div>
  );
}
