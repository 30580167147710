import React, { useState, useEffect } from "react";
// images
import AppleStore from "../../assets/images/vidCon/appleStore.png";
import PlayStore from "../../assets/images/vidCon/playsStore.png";

export default function RedirectStore() {
  const [errorOs, setErrorOs] = useState(false);
  const userOs = navigator.userAgent;
  const iOS = !!userOs.match(/iPhone|iPad|iPod/);
  const isAndroid = /android/i.test(userOs);

  function redirectLinkStore(e) {
    if (e === "android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.inteegra.vidconsp";
    } else {
      window.location.href =
        "https://apps.apple.com/us/app/vidcon-s%C3%A3o-paulo/id6448805508";
    }
  }

  useEffect(() => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.inteegra.vidconsp";
    } else if (iOS) {
      window.location.href =
        "https://apps.apple.com/us/app/vidcon-s%C3%A3o-paulo/id6448805508";
    } else {
      setErrorOs(true);
    }
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {errorOs ? (
        <div>
          <a>Selecione sua loja de aplicativos</a>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <img
              src={AppleStore}
              className="img-logo-store"
              onClick={() => redirectLinkStore("ios")}
            />
            <img
              src={PlayStore}
              className="img-logo-store"
              onClick={() => redirectLinkStore("android")}
            />
          </div>
        </div>
      ) : (
        <a>Você está sendo direcionado pra loja do seu celular</a>
      )}
    </div>
  );
}
