import React, { useState } from "react";
// componentes
import BottomMenu from "./bottomMenu";
import MenuSecurity from "./menuSecurity/menuSecurity";
import MenuFather from "./menuFather/menuFather";
import MenuFaq from "./menuFaq/menuFaq";

export default function Services() {
  const [step, setStep] = useState("a");
  return (
    <section style={{ paddingBottom: 100 }}>
      {step === "a" && <MenuSecurity />}
      {step === "b" && <MenuFather />}
      {step === "c" && <MenuFaq />}
      <BottomMenu setStep={setStep} step={step} />
    </section>
  );
}
