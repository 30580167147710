import React, { useState, useEffect } from "react";
// componente
import OptionSchedule from "../optionSchedule";

export default function Schedule07(props) {
  const [scheduleList, setScheduleList] = useState([]);
  const [mySchedule, setMySchedule] = useState(
    localStorage.getItem('schedule07')
      ? JSON.parse(localStorage.getItem('schedule07'))
      : []
  );
useEffect(() => {
  localStorage.setItem('schedule07', JSON.stringify(mySchedule));
}, [mySchedule]);

useEffect(() => {
  var dataList = [];
  props.scheduleList.map((e) => {
    e.theme_names.map((theme) => {
      if (theme.name === 'Industry Track') {
        dataList.push(e);
      }
    });
  });
  const arrayFormated = dataList.filter((e, i, self) => {
    return self.indexOf(e) === i;
  });
  setScheduleList(arrayFormated);
}, []);
return (
  <div style={{ display: 'flex', flexDirection: 'column', padding: '5%' }}>
    {scheduleList
      .filter((e) =>
        e.title.toLocaleLowerCase().includes(props.search.toLowerCase())
      )
      .filter(
        (e) =>
          e.start_time.substr(0, 10).split('-').reverse().join('-') ===
          '07-07-2023'
      )
      .sort((a, b) => Date.parse(a.start_time) - Date.parse(b.start_time))
      .map((e, i) => (
        <OptionSchedule
          mySchedule={mySchedule}
          setMySchedule={setMySchedule}
          title={e.title}
          data={e}
          key={i}
        />
      ))}
  </div>
);
}
