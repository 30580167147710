import React from "react";
// img
import Profile from "../../../assets/images/vidCon/profile.png";

export default function ModalInfoSpeakers(props) {
  const { isOpen, toggle } = props;

  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? "0%" : "100%",
      }}
    >
      <i className="mdi mdi-chevron-left close-menu" onClick={toggle} />

      <img src={Profile} style={{ width: "100%" }} />

      <div className="limit">
        <div className="div-title" style={{ fontWeight: "bold", fontSize: 25 }}>
          Palestrante X
        </div>

        <a>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries,
        </a>

        <div className="div-icons-sponsors">
          <i className="mdi mdi-facebook" style={{ fontSize: 30 }} />
          <i
            className="mdi mdi-instagram"
            style={{ fontSize: 30, margin: "0 20px" }}
          />
          <i className="mdi mdi-twitter" style={{ fontSize: 30 }} />
        </div>
      </div>
    </div>
  );
}
