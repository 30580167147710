import React, { useState, useEffect } from "react";
// componente
import OptionSchedule from "./optionSchedule";
import { data } from "./Model";

export default function Schedule09(props) {
  const [mySchedule, setMySchedule] = useState(
    localStorage.getItem("schedule09Meet")
      ? JSON.parse(localStorage.getItem("schedule09Meet"))
      : []
  );

  useEffect(() => {
    localStorage.setItem("schedule09Meet", JSON.stringify(mySchedule));
  }, [mySchedule]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '5%' }}>
      {data &&
        data
          .filter((e) => e.date === '2023/07/09')
          .filter((e) =>
            e.title.toLocaleLowerCase().includes(props.search.toLowerCase())
          )
          .map((item, i) => (
            <OptionSchedule
              mySchedule={mySchedule}
              setMySchedule={setMySchedule}
              date={item.date}
              title={item.title}
              startEnd={item.startEnd}
              logo={item.logo}
              local={item.local}
              description={item.description}
              instagram={item.instagram}
              twitter={item.twitter}
              id={item.id}
              key={i}
            />
          ))}
    </div>
  );
}
