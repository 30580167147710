export const data = [
  {
    date: '2023/07/08',
    title: 'Ivan Baron',
    startEnd: '12:00 - 13:00',
    logo: require('../../../../../../assets/images/vidCon/ivan-baron.jpg'),
    local: 'Sala 1',
    description: `Ivan Baron,  25 anos,  conhecido como influenciador da inclusão, é um potiguar que luta pelo direito e inclusão das pessoas com deficiência no país. Por meio de conteúdos divertidos e educativos, o pedagogo compartilha suas vivências e transborda suas redes de informações a respeito da luta anti capacitista. Desde 2018 produzindo vídeos socioeducativos, hoje já acumula mais de 1 milhão de seguidores nas plataformas digitais. Além disso, o jovem também compõe o time de conselheiros do Pacto Global da ONU, subiu a rampa de Brasília na Posse, ao lado do presidente Lula, e esse ano ainda foi nomeado Embaixador da Vacinação no Brasil.`,
    instagram: 'https://www.instagram.com/ivanbaron/',
    twitter: '',
    id: 1,
  },
  {
    date: '2023/07/08',
    title: 'Lumena Aleluia',
    startEnd: '12:00 - 13:00',
    logo: require('../../../../../../assets/images/vidCon/lumena.JPG'),
    local: 'Sala 2',
    description: `Lumena Aleluia é a baiana que ficou conhecida no Brasil através do Big Brother Brasil 2021, edição marcada por bater recordes de audiência que o programa não alcançava há onze anos. Atualmente, Lumena se destaca nas redes sociais empreendendo enquanto influenciadora digital. Através dos seus famosos bordões, a artista conseguiu emplacar diversas campanhas nas plataformas digitais. Na imprensa, podemos ver o rosto da blogueira em capas de revistas e jornais que diariamente replicam os diários acontecimentos do seu cotidiano. Pluralidade é o seu codinome, carrega consigo sua representação regional, racial e de gênero. Lumena também é DJ, dançarina e roteirista e revela ter encontrado na carreira artística um caminho de autoconhecimento.`,
    instagram: 'https://www.instagram.com/lumena.aleluia',
    twitter: 'https://twitter.com/lumenaaleluia?s=21&t=x7RjGqbrW4Q81UtVwG7woA',
    id: 2,
  },
  {
    date: '2023/07/08',
    title: 'Larissa Manoela',
    startEnd: '12:30 - 13:30',
    logo: require('../../../../../../assets/images/vidCon/larissa.jpg'),
    local: 'Sala 3',
    description: `22 anos de idade e 18 anos de carreira, Larissa Manoela tem mais de 270 trabalhos realizados! Ela é atriz, cantora, dubladora, escritora, influenciadora e empresária.`,
    instagram: 'https://www.instagram.com/larissamanoela/',
    twitter: '',
    id: 3,
  },
  {
    date: '2023/07/08',
    title: 'Manu Gavassi',
    startEnd: '14:30 - 15:30',
    logo: require('../../../../../../assets/images/vidCon/manugavassi.jpg'),
    local: 'Sala 2',
    description: `Manu Gavassi é cantora, compositora, atriz, diretora e roteirista. Participou do BBB20 e fez história comandando uma das melhores estratégias de marketing já vista em um reality show. Além disso, se destacou na Forbes Under 30, atua como diretora criativa em todas as marcas que trabalha  e teve seu álbum “GRACINHA”, roteirizado e dirigido por ela, como o terceiro projeto visual a entrar no Disney +, ao lado de Beyoncé e Billie Eillish. Somando mais de 68 prêmios, Manu iniciou 2023 lançando o “Manu Gavassi canta Fruto Proibido”,onde homenageou Rita Lee, em um projeto inédito que virou turnê pelo Brasil.`,
    instagram: 'https://www.instagram.com/manugavassi/?hl=pt-br',
    twitter: '',
    id: 4,
  },
  {
    date: '2023/07/08',
    title: 'PlayHard',
    startEnd: '14:30 - 15:30',
    logo: require('../../../../../../assets/images/vidCon/playhard.jpg'),
    local: 'Sala 3',
    description: `Co-fundador da LOUD e um dos maiores criadores de conteúdo do Brasil, Bruno "PlayHard" Bittencourt tem se dedicado a criar conteúdo inovador e inspirador para a comunidade gamer, tendo sido um grande destaque nos principais títulos, como Free Fire, Clash Royale e Clash of Clans. Com uma visão empreendedora afiada e uma habilidade inigualável para se conectar com o público, Bruno é uma referência no mercado de games. Sua trajetória, ilustrada pela criação da Spacecaps, é um exemplo de como é possível transformar a paixão pelos jogos em uma série de negócios de sucesso.`,
    instagram: 'https://www.instagram.com/brunoplayhard/',
    twitter: '',
    id: 5,
  },
  {
    date: '2023/07/08',
    title: 'Porta dos Fundos',
    startEnd: '15:00 - 16:00',
    logo: require('../../../../../../assets/images/vidCon/portadosfundos1.jpg'),
    local: 'Sala 1',
    description: `O Porta dos Fundos é um hub de entretenimento multiformato e multiplataforma que completou 10 anos. Com uma comunidade nacional de 37 milhões de fãs e mais de 11 bilhões de visualizações nos seus multicanais, hoje o Porta está presente também na Polônia e México, com a divisão Backdoor. Sua originalidade rendeu-lhe o Emmy Internacional de Comédia em 2019 pelo Especial de Natal, feito para a Netflix; o prêmio Caboré de Veículo de Comunicação – Produtor de Conteúdo, em 2021, além de ter sido destaque em pesquisa do DataFolha/2021 como “a marca de conteúdo humorístico mais lembrada do país”, em 2022.`,
    instagram: 'https://www.instagram.com/portadosfundos/',
    twitter: '',
    id: 6,
  },
  {
    date: '2023/07/08',
    title: 'Grupo talentos Globo',
    startEnd: '17:00 - 18:00',
    logo: require('../../../../../../assets/images/vidCon/globais.jpg'),
    local: 'Sala 1',
    description: ``,
    instagram: '',
    twitter: '',
    id: 7,
  },
  {
    date: '2023/07/08',
    title: 'Bianca Andrade',
    startEnd: '17:00 - 18:00',
    logo: require('../../../../../../assets/images/vidCon/bianca-andrade.jpg'),
    local: 'Sala 2',
    description: `Com mais de 30 milhões de seguidores nas redes sociais, a influenciadora e empresária Bianca Andrade é um símbolo feminino da atualidade. Ela começou na internet há 12 anos e, hoje, é referência em marketing digital e dona da holding Boca Rosa Company, que engloba a Boca Rosa Beauty – marca que faturou mais de R$160 milhões em 2022. No mesmo ano, Bianca foi considerada uma das 20 mulheres de destaque do ano pela Forbes.`,
    instagram: 'https://www.instagram.com/bianca/',
    twitter: '',
    id: 8,
  },
  {
    date: '2023/07/08',
    title: 'Bob Esponja & Patrick Estrela',
    startEnd: '17:00 - 17:30',
    logo: require('../../../../../../assets/images/vidCon/bob.jpg'),
    local: 'Sala 3',
    description: ``,
    instagram: '',
    twitter: '',
    id: 9,
  },
  {
    date: '2023/07/08',
    title: 'Tartarugas Ninja',
    startEnd: '17:30 - 18:00',
    logo: require('../../../../../../assets/images/vidCon/tartaruga.jpg'),
    local: 'Sala 3',
    description: ``,
    instagram: '',
    twitter: '',
    id: 10,
  },
  /* {
    date: "2023/07/08",
    title: "Vê Collen",
    startEnd: "17:30 - 18:30",
    logo: require("../../../../../../assets/images/vidCon/vcollen.jpeg"),
    local: "Sala 3",
    description: `Victória Collen, mais conhecida como Ve Collen, é uma influencer
              nascida em Goiânia, mas com coração paulista! Ela tem mais de 600
              mil seguidores somados em suas redes sociais, sendo elas TikTok,
              Instagram e Twitter. Seu conteúdo, além de lifestyle, beleza e
              moda, se destaca pelo humor, característica cultivada pela
              influenciadora como forma de trazer diversão para seu público.
              Desde pequena sonhava em ser atriz ou apresentadora de TV para
              entreter as pessoas, trazendo alegria para suas vidas. E foi
              assim, a partir desse sonho, que conseguiu conquistar seu lugar na
              internet, se tornando um símbolo de diversidade, sendo uma das
              maiores influenciadoras transexuais do mercado.`,
    instagram: "https://www.instagram.com/vecollen/?igshid=YmMyMTA2M2Y%3D",
    twitter: "",
    id: 11,
  }, */
  // {
  //   date: "2023/07/08",
  //   title: "Júnior Caldeirão",
  //   startEnd: "17:30 - 18:30",
  //   logo: require("../../../../../../assets/images/vidCon/junior-caldeirao-sm.png"),
  //   local: "Sala 3",
  //   description: ``,
  //   instagram: "",
  //   twitter: "",
  // id: ""
  // },
  // date from 09/08/2023
  {
    date: '2023/07/09',
    title: 'Pequena Lo',
    startEnd: '12:00 - 13:00',
    logo: require('../../../../../../assets/images/vidCon/pequena-lo.jpg'),
    local: 'Sala 2',
    description: `Ativista pelo direito das pessoas com deficiência, psicóloga, apresentadora, humorista e uma das maiores influenciadoras do cenário atual, Lorrane Silva ou Pequena Lo, como é conhecida, tornou- se um verdadeiro ícone da internet. Aos 27 anos, faz parte da Forbes Under 30, que destaca as personalidades mais brilhantes de até 30 anos que revolucionam e mudam o mundo. A jovem de Araxá, Minas Gerais, compartilha sua rotina e experiências com muito bom humor para os seus mais de 10 milhões de seguidores nas redes sociais.`,
    instagram: 'https://www.instagram.com/_pequenalo/',
    twitter: '',
    id: 12,
  },
  {
    date: '2023/07/09',
    title: 'Jessie Shen/Allan Jeon',
    startEnd: '12:30 - 13:30',
    logo: require('../../../../../../assets/images/vidCon/jessie.jpg'),
    local: 'Sala 1',
    description: ``,
    instagram: '',
    twitter: '',
    id: 13,
  },
  // {
  //   date: '2023/07/09',
  //   title: 'Porta dos Fundos',
  //   startEnd: '14:30 - 15:30',
  //   logo: require('../../../../../../assets/images/vidCon/portadosfundos2.jpg'),
  //   local: 'Sala 1',
  //   description: `O Porta dos Fundos é um hub de entretenimento multiformato e multiplataforma que completou 10 anos. Com uma comunidade nacional de 37 milhões de fãs e mais de 11 bilhões de visualizações nos seus multicanais, hoje o Porta está presente também na Polônia e México, com a divisão Backdoor. Sua originalidade rendeu-lhe o Emmy Internacional de Comédia em 2019 pelo Especial de Natal, feito para a Netflix; o prêmio Caboré de Veículo de Comunicação – Produtor de Conteúdo, em 2021, além de ter sido destaque em pesquisa do DataFolha/2021 como “a marca de conteúdo humorístico mais lembrada do país”, em 2022.`,
  //   instagram: 'https://www.instagram.com/portadosfundos/',
  //   twitter: '',
  //   id: 14,
  // },
  {
    date: '2023/07/09',
    title: 'Bob Esponja & Patrick Estrela',
    startEnd: '14:30 - 15:00',
    logo: require('../../../../../../assets/images/vidCon/bob.jpg'),
    local: 'Sala 1',
    description: ``,
    instagram: '',
    twitter: '',
    id: 14,
  },
  {
    date: '2023/07/09',
    title: 'Vanessa Lopes',
    startEnd: '14:30 - 15:30',
    logo: require('../../../../../../assets/images/vidCon/vanessalopes.jpg'),
    local: 'Sala 2',
    description: `Vanessa Lopes conquistou mais de 44 milhões de seguidores nas redes sociais em apenas 3 anos. Com 21 anos, é uma das principais personalidades que representam a geração Z, destacando-se no universo da dança e música. Seu sucesso rendeu grandes parcerias comerciais e visibilidade nos maiores veículos de comunicação do Brasil, além de também ter conquistado reconhecimento em outros países, expandindo sua influência além das fronteiras nacionais. Premiada no TikTok Awards como Melhor Vídeo em 2022, Vanessa é uma figura inspiradora para jovens em todo Brasil e, além da dança, compartilha aspectos de sua rotina, viagens, família e esportes, permitindo que sua audiência conheça mais sobre sua personalidade e estabeleça com ela uma forte relação de confiança. Assim, Vanessa Lopes é uma influenciadora, capaz de entreter, inspirar e conectar com seus seguidores de maneira única e significativa.`,
    instagram: 'https://www.instagram.com/vanessalopesr/',
    twitter: '',
    id: 15,
  },
  // {
  //   date: "2023/07/09",
  //   title: "Luccas Abreu / Allan Jeon",
  //   startEnd: "14:30 - 15:30",
  //   logo: require("../../../../../../assets/images/vidCon/lucas-allan2.png"),
  //   local: "Sala 3",
  //   description: ``,
  //   instagram: "",
  //   twitter: "",
  //   id: 10,
  // },
  {
    date: '2023/07/09',
    title: 'Authentic Games',
    startEnd: '17:00 - 18:00',
    logo: require('../../../../../../assets/images/vidCon/authentic-games-sm2.png'),
    local: 'Sala 1',
    description: `Canal do Youtube criado em 2011 pelo youtuber Marco Tulio.`,
    instagram: '',
    twitter: '',
    id: 16,
  },
  {
    date: '2023/07/09',
    title: 'Camila Loures',
    startEnd: '17:00 - 18:00',
    logo: require('../../../../../../assets/images/vidCon/camila-lourdes.png'),
    local: 'Sala 2',
    description: `Eleita a melhor youtuber da América Latina em 2019, Camila Loures produz conteúdos sobre entretenimento familiar, desafios, música e brincadeiras gravadas com a Família Loures. Além de creator, ela também é cantora e autora de três livros: “O Manual de Sobrevivência do adolescente” , “24h com Camila Loures” e o "Vida de Popstar". Sua carreira musical conta com oito singles e quase 80 milhões de views. Seu single “Bambolê” entrou para o Top 50 do Spotify Brasil e foi tema da campanha “Vai no Gás” da CocaCola. Recentemente, Camila Loures retomou juntamente com Lucas Guimarães para a terceira temporada de PodCats, onde contou com 15 episódios alcançando números incríveis e muita repercussão mediante aos artistas e também em outras plataformas. Atualmente, o PodCats está na quarta temporada com muita diversão e entretenimento!`,
    instagram: 'https://www.instagram.com/camilaloures/',
    twitter: '',
    id: 17,
  },
  // {
  //   date: '2023/07/09',
  //   title: 'Sofia Santino',
  //   startEnd: '17:30 - 18:30',
  //   logo: require('../../../../../../assets/images/vidCon/sofia-santino.jpg'),
  //   local: 'Sala 3',
  //   description: `Conhecida como a rainha dos delineados no TikTok, Sofia Santino atrai fãs compartilhando a sua rotina nas redes sociais. A jovem escorpiana de 22 anos, trabalha humor e criatividade em conteúdos que falam sobre seu estilo de vida em diversas frentes: beleza, moda, viagens, relacionamentos, entre outros.`,
  //   instagram: 'https://www.instagram.com/sofiasantino/',
  //   twitter: 'https://twitter.com/sofiasantino',
  //   id: 18,
  // },
  {
    date: '2023/07/09',
    title: 'Tartarugas Ninja',
    startEnd: '17:30 - 18:00',
    logo: require('../../../../../../assets/images/vidCon/tartaruga.jpg'),
    local: 'Sala 3',
    description: ``,
    instagram: '',
    twitter: '',
    id: 18,
  },
];
