import React, { useState } from "react";
// componente
// import Schedule07 from "./schedule07";
import Schedule08 from "./schedule08";
import Schedule09 from "./schedule09";

export default function ModalSchedule(props) {
  const { isOpen, toggle } = props;
  const [search, setSearch] = useState("");
  // data
  const [stepDate, setStepDate] = useState("b");

  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? '0%' : '100%',
        paddingTop: 60,
        backgroundColor: '#f1f3f5',
      }}
    >
      <i className="mdi mdi-chevron-left close-menu" onClick={toggle} />

      <div className="div-title" style={{ fontWeight: 'bold', fontSize: 25 }}>
        {props.title}
      </div>

      <div className="div-padding" style={{ backgroundColor: 'white' }}>
        <input
          placeholder="Pesquise"
          className="search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="limit">
        <div className="row-options">
          <a
            className={`option-row-options ${
              stepDate === 'b' && 'option-row-options-selected'
            }`}
            onClick={() => setStepDate('b')}
          >
            08 de Julho
          </a>
          <a
            className={`option-row-options ${
              stepDate === 'c' && 'option-row-options-selected'
            }`}
            onClick={() => setStepDate('c')}
          >
            09 de Julho
          </a>
        </div>

        {/* {stepDate === "a" && <Schedule07 title={props.title} />} */}
        {stepDate === 'b' && (
          <Schedule08
            title={props.title}
            scheduleList={props.scheduleList}
            search={search}
          />
        )}
        {stepDate === 'c' && (
          <Schedule09
            title={props.title}
            scheduleList={props.scheduleList}
            search={search}
          />
        )}
      </div>
    </div>
  );
}
