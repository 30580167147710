import React from 'react';
import './styled.css';
// img
import tiktokSvg from '../../assets/images/vidCon/tiktok.svg';

export default function ModalInfo(props) {
  const { isOpen, toggle, data } = props;

  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? '0%' : '100%',
      }}
    >
      <i className="mdi mdi-chevron-left close-menu" onClick={toggle} />

      {/* <i
        className="mdi mdi-share close-menu"
        style={{ right: 0, left: 'auto' }}
      /> */}

      <img src={data.img} className="img-modal" />

      <div className="limit">
        <div className="div-title">{data.name ? <a>{data.name}</a> : null}</div>

        {/* <a>Sobre</a> */}
        <a dangerouslySetInnerHTML={{ __html: data.description }} />

        <div className="div-icons-sponsors" style={{ marginTop: '14px' }}>
          {data?.facebook && (
            <a href={data.facebook} target="_blank" rel="noreferrer">
              <i
                className="mdi mdi-facebook"
                style={{ fontSize: 30, margin: '0 20px' }}
              />
            </a>
          )}
          {data?.instagram && (
            <a href={data.instagram} target="_blank" rel="noreferrer">
              <i
                className="mdi mdi-instagram"
                style={{ fontSize: 30, margin: '0 20px', color: '#BD2E7E' }}
              />
            </a>
          )}
          {data?.twitter && (
            <a href={data.twitter} target="_blank" rel="noreferrer">
              <i
                className="mdi mdi-twitter"
                style={{ fontSize: 30, margin: '0 20px' }}
              />
            </a>
          )}
          {data?.tiktok && (
            <a
              href={data?.tiktok}
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: '10px', marginTop: '8px' }}
            >
              <img src={tiktokSvg} alt="tiktok" width="27px" height="27px" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
