import React from "react";

export default function BottomMenu(props) {
  return (
    <div className="bottom-menu">
      <div
        className={`item-menu ${props.step === "a" && "item-menu-selected"}`}
        onClick={() => props.setStep("a")}
      >
        <i className="mdi mdi-calendar" style={{ fontSize: 30 }} />
        <a>Programação</a>
      </div>

      <div
        className={`item-menu ${props.step === "b" && "item-menu-selected"}`}
        onClick={() => props.setStep("b")}
      >
        <i
          className="mdi mdi-account"
          style={{ fontSize: 30 }}
        />
        <a>Lineup</a>
      </div>

      <div
        className={`item-menu ${props.step === "c" && "item-menu-selected"}`}
        onClick={() => props.setStep("c")}
      >
        <i
          className="mdi mdi-book-check-outline"
          style={{ fontSize: 30 }}
        />
        <a>Minha Agenda</a>
      </div>
    </div>
  );
}
