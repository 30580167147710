import React, { useEffect, useState } from "react";
// img
import VidCon from "../../../assets/images/vidCon/map.png";
// componentes
import Lecture from "./lecture";
// import SkeletonModal from "../../../components/skeleton/modal";
//service
import { getSpeaker } from "../../../utils/services/index";
import * as Sentry from '@sentry/react';

export default function ModalInfo(props) {
  const { isOpen, toggle, data, scheduleList } = props;
  const [dataSpeaker, setDataSpeaker] = useState();
  const [loading, setLoading] = useState(true);
  const [scheduleListPart, setScheduleListPart] = useState([]);

  function formateHours(e) {
    const date = new Date(e);
    const hours =
      date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return hours + ':' + minutes;
  }

  function formateDate(e) {
    var dateFormated = e.substr(0, 10).split('-').reverse().join('-');

    return dateFormated;
  }

  function shareSchedule() {
    const data = {
      message: 'sharing-data',
      data: {
        text: `${dataSpeaker.full_publication_name} está confirmado na VidCon São Paulo\n\nPara saber mais informações baixe o APP\nhttps://paramount.sistemainteegra.com.br/store`,
      },
    };

    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }

  async function getSpeakerInfo() {
    getSpeaker(data.id)
      .then((res) => {
        //console.log('pales', res);
        setDataSpeaker(res.data.response);
        setLoading(false);
      })
      .catch((error) => {
        Sentry.setContext('response', error?.response?.data);
        Sentry.captureMessage('Request Get getSpeakerInfo from ModalInfo');
      });
  }

  useEffect(() => {
    if (data && isOpen) {
      getSpeakerInfo();
    }
  }, [data, isOpen]);

  useEffect(() => {
    if (isOpen && dataSpeaker) {
      var lecuteArray = [];

      scheduleList.map((schedule) => {
        schedule.sorted_published_item_assignments.map((person) => {
          if (person.person.id === dataSpeaker.id) {
            lecuteArray.push(schedule);
          }
        });
      });

      const arrayFormated = lecuteArray.filter((e, i, self) => {
        return self.indexOf(e) === i;
      });
      setScheduleListPart(arrayFormated);
    }
  }, [scheduleList, isOpen, dataSpeaker]);

  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? '0%' : '100%',
      }}
    >
      <i
        className="mdi mdi-chevron-left close-menu"
        onClick={() => {
          toggle();
          setTimeout(() => {
            setLoading(true);
          }, 400);
        }}
      />

      <i
        className="mdi mdi-share close-menu"
        style={{ right: 0, left: 'auto' }}
        onClick={() => shareSchedule()}
      />

      {loading ? (
        <></>
      ) : (
        <>
          {dataSpeaker && (
            <img src={dataSpeaker.public_image_url} className="img-modal" />
          )}

          <div className="limit">
            <div
              className="div-title"
              // onClick={() => console.log(scheduleListPart)}
            >
              {dataSpeaker && dataSpeaker.full_publication_name}
            </div>

            {dataSpeaker && (
              <a
                dangerouslySetInnerHTML={{ __html: dataSpeaker.edited_bio.bio }}
              />
            )}

            <div className="div-icons-sponsors">
              {dataSpeaker && dataSpeaker.edited_bio.facebook && (
                <a
                  href={dataSpeaker.edited_bio.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="mdi mdi-facebook"
                    style={{ fontSize: 30, margin: '0 10px' }}
                  />
                </a>
              )}

              {dataSpeaker && dataSpeaker.edited_bio.instagram && (
                <a
                  href={dataSpeaker.edited_bio.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="mdi mdi-instagram"
                    style={{ fontSize: 30, margin: '0 10px' }}
                  />
                </a>
              )}

              {dataSpeaker && dataSpeaker.edited_bio.twitterinfo && (
                <a
                  href={dataSpeaker.edited_bio.twitterinfo}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="mdi mdi-twitter"
                    style={{ fontSize: 30, margin: '0 10px' }}
                  />
                </a>
              )}

              {dataSpeaker && dataSpeaker.edited_bio.twitch && (
                <a
                  href={dataSpeaker.edited_bio.twitch}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="mdi mdi-twitch"
                    style={{ fontSize: 30, margin: '0 10px' }}
                  />
                </a>
              )}
            </div>

            <div
              className="div-title"
              style={{ borderBottom: 0, marginTop: 20 }}
            >
              Quais sessões eu vou estar?
            </div>

            {scheduleListPart.map((e, i) => (
              <Lecture key={i} data={e} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
