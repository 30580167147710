import React from "react";
//imgs
import tiktokSvg from "../../assets/images/vidCon/tiktok.svg";

export default function Sponsors(props) {
  return (
    <div className="social-media">
      <a
        href="https://www.facebook.com/vidconsaopaulo/"
        target="_blank"
        rel="noreferrer"
      >
        <i className="mdi mdi-facebook" />
        <p>/vidconsaopaulo</p>
      </a>

      <a
        href="https://www.instagram.com/vidconsaopaulo/"
        target="_blank"
        rel="noreferrer"
        style={{ color: "#EA2495" }}
      >
        <i className="mdi mdi-instagram" />
        <p>@vidconsaopaulo</p>
      </a>
      <a
        href="https://twitter.com/vidconsaopaulo"
        target="_blank"
        rel="noreferrer"
      >
        <i className="mdi mdi-twitter" />
        <p style={{ marginBottom: "30px" }}>@vidconsaopaulo</p>
      </a>
      <a
        href=" https://www.tiktok.com/@vidconsaopaulo"
        target="_blank"
        rel="noreferrer"
        style={{ marginTop: "45px" }}
      >
        <img src={tiktokSvg} alt="tiktok" width="60px" height="60px" />
        <p style={{ marginTop: "20px", color: "gray" }}>@vidconsaopaulo</p>
      </a>
      <a
        href="https://www.youtube.com/channel/UC4q9ntaBJEhxSkopzS0C3-w"
        target="_blank"
        rel="noreferrer"
        style={{ color: "#FA383E" }}
      >
        <i className="mdi mdi-youtube" />
        <p>VidCon São Paulo</p>
      </a>
    </div>
  );
}
