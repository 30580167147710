import React, { useState } from "react";
// componentes
import ModalSchedule from "./modalSchedule";

export default function OptionCommunityProgramming(props) {
  const [modalType, setModalType] = useState(false);
  return (
    <>
      <ModalSchedule
        isOpen={modalType}
        toggle={() => setModalType(!modalType)}
        title={props.title}
        scheduleList={props.scheduleList}
      />
      <div className="option-schedule" onClick={() => setModalType(true)}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <a style={{ fontWeight: 'bold' }}>{props.title}</a>

          <i className="mdi mdi-chevron-right" />
        </div>
      </div>
    </>
  );
}
