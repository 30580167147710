import React, { useState, useEffect } from "react";
// componentes
// import SkeletonList from "../../../../components/skeleton/list";
import Schedule07 from "./schedule07";
import Schedule08 from "./schedule08";
import Schedule09 from "./schedule09";
import { LoadSpinner } from "../../../../components/LoadingSpinner";

export default function MenuWhen(props) {
  const [stepDate, setStepDate] = useState("a");
  // pesquisa
  const [search, setSearch] = useState("");

  return (
    <div style={{ paddingTop: 50 }}>
      <div className="div-padding" style={{ backgroundColor: "white" }}>
        <input
          placeholder="Pesquise"
          className="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="row-options">
        <a
          className={`option-row-options ${
            stepDate === "a" && "option-row-options-selected"
          }`}
          onClick={() => setStepDate("a")}
        >
          07 de Julho
        </a>
        <a
          className={`option-row-options ${
            stepDate === "b" && "option-row-options-selected"
          }`}
          onClick={() => setStepDate("b")}
        >
          08 de Julho
        </a>
        <a
          className={`option-row-options ${
            stepDate === "c" && "option-row-options-selected"
          }`}
          onClick={() => setStepDate("c")}
        >
          09 de Julho
        </a>
      </div>

      {props.loading ? (
        <div className="spinnerloading-container">
          <LoadSpinner />
        </div>
      ) : (
        // <SkeletonList count={7} />
        <>
          {stepDate === "a" && (
            <Schedule07
              search={search}
              setSearch={setSearch}
              scheduleList={props.scheduleList}
            />
          )}
          {stepDate === "b" && (
            <Schedule08
              search={search}
              setSearch={setSearch}
              scheduleList={props.scheduleList}
            />
          )}
          {stepDate === "c" && (
            <Schedule09
              search={search}
              setSearch={setSearch}
              scheduleList={props.scheduleList}
            />
          )}
        </>
      )}
    </div>
  );
}
