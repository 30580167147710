import React, { useState, useEffect } from "react";
// nomes na listagem
import { exhibitorsSchedulle } from "../../utils/ExhibitorsSchedulle/index";
// componentes
import ModalInfo from "./modalInfoFoodDrink";

export default function MenuFoodDrink() {
  const [search, setSearch] = useState('');
  const [dataSchedule, setDataSchedule] = useState([]);
  // modal
  const [modalInfo, setModalInfo] = useState(false);
  const [dataModalInfo, setDataModalInfo] = useState({
    name: '',
    img: '',
  });

  useEffect(() => {
    let data = exhibitorsSchedulle
      .filter((e) =>
        e.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      .reduce((r, e) => {
        let group = e.name[0].toLocaleUpperCase();
        if (!r[group]) r[group] = { group, children: [e] };
        else r[group].children.push(e);
        return r;
      }, {});

    setDataSchedule(Object.values(data));
  }, [search]);
  return (
    <div className="list-itens-column">
      <ModalInfo
        isOpen={modalInfo}
        toggle={() => setModalInfo(!modalInfo)}
        data={dataModalInfo}
      />
      <div className="div-padding" style={{ backgroundColor: 'white' }}>
        <input
          placeholder="Pesquise"
          className="search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="div-padding" style={{ padding: '0 5%', marginTop: 20 }}>
        {dataSchedule &&
          dataSchedule
            .sort(function (a, b) {
              if (a.group < b.group) {
                return -1;
              }
              if (a.group > b.group) {
                return 1;
              }
              return 0;
            })
            .map((e, i) => (
              <div key={i}>
                <div style={{ margin: '8px 0', display: 'flex' }}>
                  <a className="title-contact">{e.group}</a>
                </div>
                {e.children.map((item, index) => (
                  <>
                    <div
                      key={index}
                      className="div-contact"
                      style={{ flexDirection: 'column' }}
                      onClick={() => {
                        setDataModalInfo({
                          name: item.name,
                          img: item.img,
                          address: item.address,
                          description: item.description,
                          menu: item.menu,
                          instagram: item.instagram,
                        });
                        setModalInfo(true);
                      }}
                    >
                      <div
                        style={{
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <img src={item.img} className="img-contact" />
                        <a>{item.name}</a>
                        <div style={{ marginLeft: '10px', paddingLeft: 0 }}>
                          <a>
                            <i className="mdi mdi-map-marker" size="30px" />{' '}
                            {item.address}
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            ))}
      </div>
    </div>
  );
}
