import React, { useState, useEffect } from "react";
// img
import Inteegra from "../../../../../assets/images/vidCon/inteegra.png";
// componentes
import ModalInfoSchedule from "./modalInfoSchedule";

export default function OptionSchedule(props) {
  const [selected, setselected] = useState(false);
  // modal
  const [modalInfo, setModalInfo] = useState(false);

  function favoriteSchedule() {
    const scheduleSave = props.mySchedule.filter(
      (e) => e.title === props.title
    );

    if (scheduleSave.length > 0) {
      var index = props.mySchedule.findIndex((e) => e.title === props.title);
      var newStateSchedule = props.mySchedule.filter((e, i) => i != index);
      props.setMySchedule(newStateSchedule);
    } else {
      props.setMySchedule((state) => {
        return [
          ...state,
          {
            title: props.title,
            id: props.id,
          },
        ];
      });
    }
  }

  useEffect(() => {
    var schedule = props.mySchedule.filter((e) => e.title === props.title);
    if (schedule.length > 0) {
      setselected(true);
    } else {
      setselected(false);
    }
  }, [props.mySchedule]);

  return (
    <div className="option-schedule">
      <ModalInfoSchedule
        isOpen={modalInfo}
        toggle={() => setModalInfo(!modalInfo)}
        date={props.date}
        title={props.title}
        logo={props.logo}
        local={props.local}
        startEnd={props.startEnd}
        description={props.description}
        instagram={props.instagram}
        twitter={props.twitter}
      />
      <div
        style={{ width: "75%", display: "flex", alignItems: "center" }}
        onClick={() => setModalInfo(true)}
      >
        <img
          style={{
            borderRadius: "50%",
            width: "65px",
            height: "65px",
            marginRight: "10px",
          }}
          src={props.logo}
          // className="img-option-schedule"
        />

        <div className="data-option-schedule">
          <a style={{ color: "blue", fontWeight: "bolder" }}>
            {props.startEnd}
          </a>
          <a style={{ fontWeight: "bold" }}>{props.title}</a>
          <a>
            <i className="mdi mdi-map-marker" />
            {props.local}
          </a>
        </div>
      </div>

      <div style={{ width: "25%", display: "flex", justifyContent: "center" }}>
        <div onClick={() => favoriteSchedule()}>
          {selected ? (
            <i
              className="mdi mdi-heart add-option-schedule"
              style={{ color: "red" }}
            />
          ) : (
            <i className="mdi mdi-heart-outline add-option-schedule" />
          )}
        </div>
      </div>
    </div>
  );
}
