import React, { useState, useEffect } from "react";
// img
import Map from "../../../../assets/images/vidCon/map.png";
// componentes
import ModalInfoSchedule from "./modalInfoSchedule";

export default function OptionWhenSchedule(props) {
  const [selected, setselected] = useState(false);
  // modal
  const [modalInfo, setModalInfo] = useState(false);

  function favoriteSchedule() {
    const scheduleSave = props.mySchedule.filter(
      (e) => e.title === props.data.title
    );

    if (scheduleSave.length > 0) {
      var index = props.mySchedule.findIndex(
        (e) => e.title === props.data.title
      );
      var newStateSchedule = props.mySchedule.filter((e, i) => i != index);
      props.setMySchedule(newStateSchedule);
    } else {
      props.setMySchedule((state) => {
        return [
          ...state,
          {
            title: props.data.title,
            id: props.data.id,
          },
        ];
      });
    }
  }

  function formateHours(e) {
    const date = new Date(e);
    const hours =
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return hours + ":" + minutes;
  }

  useEffect(() => {
    var schedule = props.mySchedule.filter((e) => e.title === props.data.title);
    if (schedule.length > 0) {
      setselected(true);
    } else {
      setselected(false);
    }
  }, [props.mySchedule]);

  return (
    <div className="option-schedule">
      <ModalInfoSchedule
        isOpen={modalInfo}
        data={props.data}
        toggle={() => setModalInfo(!modalInfo)}
      />
      <div
        style={{ width: "75%", display: "flex", alignItems: "center" }}
        // onClick={() => console.log(props.data)}
        onClick={() => setModalInfo(true)}
      >
        {/* <img src={Map} className="img-option-schedule" /> */}

        <div
          className="data-option-schedule"
          style={{
            paddingLeft: 10,
          }}
        >
          {props.data.start_time ? (
            <a
              style={{
                color: "blue",
                fontWeight: "bolder",
                display: props.disableTime ? "none" : "block",
              }}
            >
              {formateHours(props.data.start_time)} -{" "}
              {formateHours(props.data.end_time)}
            </a>
          ) : null}

          <a style={{ fontWeight: "bold" }}>{props.data.title}</a>
          <a>
            <i className="mdi mdi-map-marker" />
            {props.data.published_room.name}
          </a>
        </div>
      </div>

      <div style={{ width: "25%", display: "flex", justifyContent: "center" }}>
        <div onClick={() => favoriteSchedule()}>
          {selected ? (
            <i
              className="mdi mdi-heart add-option-schedule"
              style={{ color: "red" }}
            />
          ) : (
            <i className="mdi mdi-heart-outline add-option-schedule" />
          )}
        </div>
      </div>
    </div>
  );
}
