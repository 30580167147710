import React, { useState, useEffect } from "react";
// componentes
import Schedule07 from "./schedule07";
import Schedule08 from "./schedule08";
import Schedule09 from "./schedule09";
import Schedule08Meet from "./meet&greet/schedule08Meet";
import Schedule09Meet from "./meet&greet/schedule09Meet";
// import SkeletonList from "../../components/skeleton/list";
import { LoadSpinner } from '../../components/LoadingSpinner';

import { data } from '../schedule/menuSchedule/menuType/optionMeetGreet/Model/index';
// service
import { getAllItems } from '../../utils/services/index';

export default function MySchedule() {
  const [loading, setLoading] = useState(true);
  // todas palestras
  const [scheduleList, setScheduleList] = useState([]);
  // geranciador de componente de datas
  const [stepDate, setStepDate] = useState('a');
  // campo pesquisa
  const [search, setSearch] = useState('');
  // dados por data
  const [mySchedule07, setMySchedule07] = useState(
    localStorage.getItem('schedule07')
      ? JSON.parse(localStorage.getItem('schedule07'))
      : []
  );
  const [mySchedule08, setMySchedule08] = useState(
    localStorage.getItem('schedule08')
      ? JSON.parse(localStorage.getItem('schedule08'))
      : []
  );
  const [mySchedule09, setMySchedule09] = useState(
    localStorage.getItem('schedule09')
      ? JSON.parse(localStorage.getItem('schedule09'))
      : []
  );
  // meet & greet
  const [mySchedule08Meet, setMySchedule08Meet] = useState(
    localStorage.getItem('schedule08Meet')
      ? JSON.parse(localStorage.getItem('schedule08Meet'))
      : []
  );
  const [mySchedule09Meet, setMySchedule09Meet] = useState(
    localStorage.getItem('schedule09Meet')
      ? JSON.parse(localStorage.getItem('schedule09Meet'))
      : []
  );

  useEffect(() => {
    if (mySchedule07.length > 0) {
      setStepDate('a');
    } else if (mySchedule08.length > 0) {
      setStepDate('b');
    } else if (mySchedule09.length > 0) {
      setStepDate('c');
    } else if (mySchedule08Meet.length > 0) {
      setStepDate('d');
    } else setStepDate('e');
  }, []);

  useEffect(() => {
    localStorage.setItem('schedule07', JSON.stringify(mySchedule07));
  }, [mySchedule07]);

  useEffect(() => {
    localStorage.setItem('schedule08', JSON.stringify(mySchedule08));
  }, [mySchedule08]);

  useEffect(() => {
    localStorage.setItem('schedule09', JSON.stringify(mySchedule09));
  }, [mySchedule09]);

  useEffect(() => {
    localStorage.setItem('schedule08Meet', JSON.stringify(mySchedule08Meet));
  }, [mySchedule08Meet]);

  useEffect(() => {
    localStorage.setItem('schedule09Meet', JSON.stringify(mySchedule09Meet));
  }, [mySchedule09Meet]);

  useEffect(() => {
    getAllItems()
      .then((res) => {
        setScheduleList(res.data.response);
        setLoading(false);
      })
      .catch((error) => console.log('error', error));
  }, []);

  return (
    <div>
      <div className="div-padding" style={{ backgroundColor: 'white' }}>
        <input
          placeholder="Pesquise"
          className="search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="row-options">
        {mySchedule07 && mySchedule07.length > 0 && (
          <a
            className={`option-row-options ${
              stepDate === 'a' && 'option-row-options-selected'
            }`}
            onClick={() => setStepDate('a')}
          >
            07 de Julho
          </a>
        )}

        {mySchedule08 && mySchedule08.length > 0 && (
          <a
            className={`option-row-options ${
              stepDate === 'b' && 'option-row-options-selected'
            }`}
            onClick={() => setStepDate('b')}
          >
            08 de Julho
          </a>
        )}

        {mySchedule09 && mySchedule09.length > 0 && (
          <a
            className={`option-row-options ${
              stepDate === 'c' && 'option-row-options-selected'
            }`}
            onClick={() => setStepDate('c')}
          >
            09 de Julho
          </a>
        )}
      </div>

      <div className="row-options">
        {mySchedule08Meet && mySchedule08Meet.length > 0 && (
          <a
            className={`option-row-options ${
              stepDate === 'd' && 'option-row-options-selected'
            }`}
            onClick={() => setStepDate('d')}
          >
            08 de Julho Meet & Greet
          </a>
        )}

        {mySchedule09Meet && mySchedule09Meet.length > 0 && (
          <a
            className={`option-row-options ${
              stepDate === 'e' && 'option-row-options-selected'
            }`}
            onClick={() => setStepDate('e')}
          >
            09 de Julho Meet & Greet
          </a>
        )}
      </div>

      {loading ? (
        <div className="spinnerloading-container">
          {/* <SkeletonList count={7} /> */}
          <LoadSpinner />
        </div>
      ) : (
        <>
          {mySchedule07.length === 0 &&
          mySchedule08.length === 0 &&
          mySchedule09.length === 0 &&
          mySchedule08Meet.length === 0 &&
          mySchedule09Meet.length === 0 ? (
            <p style={{ textAlign: 'center' }}>
              Você ainda não selecionou nenhuma programação!
            </p>
          ) : (
            <>
              {stepDate === 'a' && (
                <Schedule07
                  mySchedule={mySchedule07}
                  setMySchedule={setMySchedule07}
                  scheduleList={scheduleList}
                  setStepDate={setStepDate}
                  other={
                    mySchedule08.length > 0 ||
                    mySchedule09.length > 0 ||
                    mySchedule08Meet.length > 0 ||
                    mySchedule09Meet.length > 0
                      ? true
                      : false
                  }
                  search={search}
                />
              )}

              {stepDate === 'b' && (
                <Schedule08
                  mySchedule={mySchedule08}
                  setMySchedule={setMySchedule08}
                  scheduleList={scheduleList}
                  setStepDate={setStepDate}
                  other={
                    mySchedule07.length > 0 ||
                    mySchedule09.length > 0 ||
                    mySchedule08Meet.length > 0 ||
                    mySchedule09Meet.length > 0
                      ? true
                      : false
                  }
                  search={search}
                />
              )}

              {stepDate === 'c' && (
                <Schedule09
                  mySchedule={mySchedule09}
                  setMySchedule={setMySchedule09}
                  scheduleList={scheduleList}
                  setStepDate={setStepDate}
                  other={
                    mySchedule08.length > 0 ||
                    mySchedule07.length > 0 ||
                    mySchedule08Meet.length > 0 ||
                    mySchedule09Meet.length > 0
                      ? true
                      : false
                  }
                  search={search}
                />
              )}

              {stepDate === 'd' && (
                <Schedule08Meet
                  mySchedule={mySchedule08Meet}
                  setMySchedule={setMySchedule08Meet}
                  scheduleList={data}
                  setStepDate={setStepDate}
                  other={
                    mySchedule08.length > 0 ||
                    mySchedule07.length > 0 ||
                    mySchedule09.length > 0 ||
                    mySchedule09Meet.length > 0
                      ? true
                      : false
                  }
                  search={search}
                />
              )}

              {stepDate === 'e' && (
                <Schedule09Meet
                  mySchedule={mySchedule09Meet}
                  setMySchedule={setMySchedule09Meet}
                  scheduleList={data}
                  setStepDate={setStepDate}
                  other={
                    mySchedule08.length > 0 ||
                    mySchedule07.length > 0 ||
                    mySchedule09.length > 0 ||
                    mySchedule08Meet.length > 0
                      ? true
                      : false
                  }
                  search={search}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
