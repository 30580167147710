import React, { useState, useEffect } from "react";
// componente
import OptionMySchedule from "./optionMySchedule";

export default function Schedule07(props) {
  const [arrayData, setArrayData] = useState([]);

  useEffect(() => {
    if (props.mySchedule.length > 0) {
      var data = [];

      props.mySchedule.map((e) => {
        var filter = props.scheduleList.find(
          (schedule) => schedule.title === e.title
        );

        if (filter) {
          data.push(filter);
        }
      });

      setArrayData(data);
    }
  }, [props.mySchedule, props.scheduleList]);

  useEffect(() => {
    if(props.mySchedule.length === 0 && props.other){
      props.setStepDate("b")
    }
  }, [props.mySchedule])
  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '5%' }}>
      {arrayData.length > 0 &&
        arrayData
          .filter((e) =>
            e.title.toLocaleLowerCase().includes(props.search.toLowerCase())
          )
          .sort((a, b) => Date.parse(a.start_time) - Date.parse(b.start_time))
          .map((e, i) => (
            <OptionMySchedule
              key={i}
              mySchedule={props.mySchedule}
              setMySchedule={props.setMySchedule}
              title={e.title}
              data={e}
            />
          ))}
    </div>
  );
}
