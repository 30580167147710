import React, { useState } from "react";
// componente
import Speackers from "./speakers";

export default function ModalInfoSchedule(props) {
  const { isOpen, toggle } = props;
  //modal ler mais
  const [modalRead, setModalRead] = useState(false);

  function formateHours(e) {
    const date = new Date(e);
    const hours =
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return hours + ":" + minutes;
  }

  function formateDate(e) {
    var dateFormated = e.substr(0, 10).split("-").reverse().join("-");

    return dateFormated.replace(/-/g, "/");
  }

  function saveSchedule() {
    const data = {
      message: "add-schedule",
      data: {
        title: props.data.title,
        start: new Date(props.data.start_time),
        end: new Date(props.data.end_time),
      },
    };

    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }

  function shareSchedule() {
    const data = {
      message: "sharing-data",
      data: {
        text: `${props.data.title}\n\n${formateDate(
          props.data.start_time
        )}\n${formateHours(props.data.start_time)}h às ${formateHours(
          props.data.end_time
        )}h\n\nPara saber mais informações baixe o APP\nhttps://paramount.sistemainteegra.com.br/store`,
      },
    };

    console.log(data);
    return;

    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }

  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? '0%' : '100%',
      }}
    >
      <i className="mdi mdi-chevron-left close-menu" onClick={toggle} />

      <i
        className="mdi mdi-share close-menu"
        style={{ right: 0, left: 'auto' }}
        onClick={() => shareSchedule()}
      />

      <div className="limit" style={{ paddingTop: 70 }}>
        <div className="div-title" style={{ fontWeight: 'bold', fontSize: 25 }}>
          {props.data.title}
        </div>

        <a>
          <i className="mdi mdi-map-marker" />
          {props.data.published_room.name}
        </a>
        <a>
          <i className="mdi mdi-calendar" />
          {formateDate(props.data.start_time)}{' '}
        </a>
        <a>
          <i className="mdi mdi-timer" />
          {formateHours(props.data.start_time)} -{' '}
          {formateHours(props.data.end_time)}
        </a>

        {props.data.description && (
          <div className="div-description-schedule">
            <div className="data-description-schedule">
              <a style={{ fontWeight: 'bold', fontSize: 17 }}>Descrição</a>
              <a dangerouslySetInnerHTML={{ __html: props.data.description }} />
            </div>
          </div>
        )}

        <div>
          {props.data.sorted_published_item_assignments.map((e, i) => (
            <Speackers data={e} key={i} />
          ))}
        </div>

        <a className="add-schedule" onClick={() => saveSchedule()}>
          Adicionar a agenda
        </a>
      </div>
    </div>
  );
}
