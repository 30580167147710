import React, { useState, useEffect } from "react";
// componente
import OptionWhenSchedule from "./optionWhenSchedule";

export default function Schedule08(props) {
  const [mySchedule7, setMySchedule7] = useState(
    localStorage.getItem("schedule07")
      ? JSON.parse(localStorage.getItem("schedule07"))
      : []
  );
  const [mySchedule8, setMySchedule8] = useState(
    localStorage.getItem("schedule08")
      ? JSON.parse(localStorage.getItem("schedule08"))
      : []
  );
  const [mySchedule9, setMySchedule9] = useState(
    localStorage.getItem("schedule09")
      ? JSON.parse(localStorage.getItem("schedule09"))
      : []
  );

  useEffect(() => {
    props.setSearch("");
  }, []);

  useEffect(() => {
    localStorage.setItem("schedule07", JSON.stringify(mySchedule7));
    localStorage.setItem("schedule08", JSON.stringify(mySchedule8));
    localStorage.setItem("schedule09", JSON.stringify(mySchedule9));
  }, [mySchedule7, mySchedule8, mySchedule9]);

  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "5%" }}>
      {props.scheduleList &&
        props.scheduleList
          .sort(function (a, b) {
            if (a.group < b.group) {
              return -1;
            }
            if (a.group > b.group) {
              return 1;
            }
            return 0;
          })
          .map((e, i) => (
            <div key={i}>
              <div style={{ margin: "8px 0", display: "flex" }}>
                <a className="title-contact">{e.group}</a>
              </div>
              {e.children.map((item, index) => (
                <>
                  {new Date(item.start_time).getDate().toString() == "7" && (
                    <OptionWhenSchedule
                      disableTime
                      mySchedule={mySchedule7}
                      setMySchedule={setMySchedule7}
                      data={item}
                    />
                  )}
                  {new Date(item.start_time).getDate().toString() == "8" && (
                    <OptionWhenSchedule
                      disableTime
                      mySchedule={mySchedule8}
                      setMySchedule={setMySchedule8}
                      data={item}
                    />
                  )}
                  {new Date(item.start_time).getDate().toString() == "9" && (
                    <OptionWhenSchedule
                      disableTime
                      mySchedule={mySchedule9}
                      setMySchedule={setMySchedule9}
                      data={item}
                    />
                  )}
                </>
              ))}
            </div>
          ))}
    </div>
  );
}
