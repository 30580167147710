import React, { useState, useEffect } from "react";
// componente
import OptionWhenSchedule from "./optionWhenSchedule";

export default function Schedule07(props) {
  const [mySchedule, setMySchedule] = useState(
    localStorage.getItem("schedule07")
      ? JSON.parse(localStorage.getItem("schedule07"))
      : []
  );

  useEffect(() => {
    props.setSearch("");
  }, []);

  useEffect(() => {
    localStorage.setItem("schedule07", JSON.stringify(mySchedule));
  }, [mySchedule]);

  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "5%" }}>
      {props.scheduleList &&
        props.scheduleList
          .filter(
            (e) =>
              e.start_time.substr(0, 10).split("-").reverse().join("-") ===
              "07-07-2023"
          )
          .filter((e) =>
            e.title.toLocaleLowerCase().includes(props.search.toLowerCase())
          )
          .map((e, i) => (
            <OptionWhenSchedule
              key={i}
              mySchedule={mySchedule}
              setMySchedule={setMySchedule}
              data={e}
            />
          ))}
    </div>
  );
}
