import React, { useState, useEffect } from "react";
// componentes
import TopMenu from "./topMenu";
import MenuWhen from "./menuWhen/menuWhen";
import MenuWhere from "./menuWhere/menuWhere";
import MenuType from "./menuType/menuType";
// service
import { getAllItems, getAllRooms } from "../../../utils/services/index";
import * as Sentry from "@sentry/react";

export default function MenuSchedule(props) {
  const [step, setStep] = useState("a");
  const [scheduleList, setScheduleList] = useState([]);
  const [loadingGetItems, setLoadingGetItems] = useState(true);
  const [roomsData, setRoomsData] = useState([]);
  const [loadingGetRooms, setLoadingGetRooms] = useState(true);

  async function getItems() {
    await getAllItems()
      .then((res) => {
        if (res?.data?.response) {
          setScheduleList(res?.data?.response);
          setLoadingGetItems(false);
        }
      })
      .catch((error) => {
        // Sentry.setContext('request', { config: 'Request Get Items' });
        Sentry.setContext("response", error?.response?.data);
        Sentry.captureMessage("Request Get Items from Schedule");
        // resolve({ success: false, data: error });
      });
  }

  async function getRooms() {
    await getAllRooms()
      .then((res) => {
        // console.log("ok", res);
        setRoomsData(res?.data?.response);
        setLoadingGetRooms(false);
      })
      .catch((error) => {
        Sentry.setContext("response", error?.response?.data);
        Sentry.captureMessage("Request Get Items from Schedule");
      });
  }

  useEffect(() => {
    getItems();
    getRooms();
  }, []);
  return (
    <section>
      <TopMenu step={step} setStep={setStep} />

      {step === "a" && (
        <MenuWhen scheduleList={scheduleList} loading={loadingGetItems} />
      )}
      {step === "b" && (
        <MenuWhere
          roomsData={roomsData}
          scheduleList={scheduleList}
          loading={loadingGetRooms}
        />
      )}
      {step === "c" && <MenuType />}
    </section>
  );
}
