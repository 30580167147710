import React, { useState, useEffect } from "react";
// img
import Inteegra from "../../assets/images/vidCon/inteegra.png";
// componentes
import ModalInfoMySchedule from "./modalInfoMySchedule";

export default function OptionMySchedule(props) {
  const [selected, setselected] = useState(false);
  // modal
  const [modalInfo, setModalInfo] = useState(false);

  function favoriteSchedule() {
    const scheduleSave = props.mySchedule.filter(
      (e) => e.title === props.title
    );

    if (scheduleSave.length > 0) {
      var index = props.mySchedule.findIndex((e) => e.title === props.title);
      var newStateSchedule = props.mySchedule.filter((e, i) => i != index);
      props.setMySchedule(newStateSchedule);
    } else {
      props.setMySchedule((state) => {
        return [
          ...state,
          {
            title: props.title,
          },
        ];
      });
    }
  }

  function formateHours(e) {
    const date = new Date(e);
    const hours =
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return hours + ":" + minutes;
  }

  // useEffect(() => {
  //   var schedule = props.mySchedule.filter((e) => e.title === props.title);
  //   if (schedule.length > 0) {
  //     console.log(schedule)
  //     setselected(true);
  //   } else {
  //     setselected(false);
  //   }
  // }, [props.mySchedule]);

  return (
    <div className="option-schedule">
      <ModalInfoMySchedule
        isOpen={modalInfo}
        toggle={() => setModalInfo(!modalInfo)}
        data={props.data}
      />
      <div
        style={{ width: "75%", display: "flex", alignItems: "center" }}
        onClick={() => setModalInfo(true)}
      >
        {/* <img src={Inteegra} className="img-option-schedule" /> */}

        <div className="data-option-schedule" style={{ paddingLeft: 10 }}>
          <a style={{ color: "blue", fontWeight: "bolder" }}>
            {formateHours(props.data.start_time)} -{" "}
            {formateHours(props.data.end_time)}
          </a>
          <a style={{ fontWeight: "bold" }}>{props.title}</a>
          <a>
            <i className="mdi mdi-map-marker" />
            {props.data.published_room.name}
          </a>
        </div>
      </div>

      <div style={{ width: "25%", display: "flex", justifyContent: "center" }}>
        <div onClick={() => favoriteSchedule()}>
          {/* {selected ? (
            <i
              className="mdi mdi-heart add-option-schedule"
              style={{ color: "red" }}
            />
          ) : (
            <i className="mdi mdi-heart-outline add-option-schedule" />
          )} */}
          <i
            className="mdi mdi-heart add-option-schedule"
            style={{ color: "red" }}
          />
        </div>
      </div>
    </div>
  );
}
