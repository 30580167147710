import React, { useState, useEffect } from "react";
import { exhibitorsSchedulle } from "../../../utils/ExhibitorsSchedulle/index";
import ModalInfoActivity from "./modalInfoActivity";
import ScheduleAll from "../../schedule/menuSchedule/menuWhen/ScheduleAll";
import { getAllItems } from "../../../utils/services";
import * as Sentry from '@sentry/react';

export default function MenuActivity() {
  const [search, setSearch] = useState("");
  const [dataSchedule, setDataSchedule] = useState([]);
  const [modalInfo, setModalInfo] = useState(false);
  const [dataModalInfo, setDataModalInfo] = useState({
    name: "",
    img: "",
  });
  async function getItems() {
    await getAllItems()
      .then((res) => {
        if (res.data.response) {
          let dataReturn = res.data.response.filter(
            (e) => e.id == 24341 || e.id == 24359 || e.id == 24520
          );

          let data = dataReturn.reduce((r, e) => {
            let group = e.title[0].toUpperCase();
            if (!r[group]) r[group] = { group, children: [e] };
            else r[group].children.push(e);
            return r;
          }, {});
          setDataSchedule(Object.values(data));
        }
      })
      .catch((error) => {
        Sentry.setContext('response', error?.response?.data);
        Sentry.captureMessage('Request Get Items from MenuType');
      });
  }
  useEffect(() => {
    getItems();
  }, []);

  return (
    <div style={{ paddingTop: 0 }}>
      <ModalInfoActivity
        isOpen={modalInfo}
        toggle={() => setModalInfo(!modalInfo)}
        data={dataModalInfo}
      />
      <div className="div-padding" style={{ backgroundColor: "white" }}>
        <input
          placeholder="Pesquise"
          className="search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <ScheduleAll
        search={search}
        setSearch={setSearch}
        scheduleList={dataSchedule.filter((item) =>
          item.children.some(
            (child) =>
              child.title.toLowerCase().indexOf(search.toLowerCase()) > -1
          )
        )}
      />
    </div>
  );

  return (
    <div className="list-itens-column">
      <ModalInfoActivity
        isOpen={modalInfo}
        toggle={() => setModalInfo(!modalInfo)}
        data={dataModalInfo}
      />
      <div className="div-padding" style={{ backgroundColor: "white" }}>
        <input
          placeholder="Pesquise"
          className="search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="div-padding" style={{ padding: "0 5%", marginTop: 20 }}>
        {dataSchedule &&
          dataSchedule
            .sort(function (a, b) {
              if (a.group < b.group) {
                return -1;
              }
              if (a.group > b.group) {
                return 1;
              }
              return 0;
            })
            .map((e, i) => (
              <div key={i}>
                <div style={{ margin: "8px 0", display: "flex" }}>
                  <a className="title-contact">{e.group}</a>
                </div>
                {e.children.map((item, index) => (
                  <div
                    key={index}
                    className="div-contact"
                    style={{ padding: "15px 20px" }}
                    onClick={() => {
                      setDataModalInfo({
                        name: item.title,
                        img: item.img,
                      });
                      setModalInfo(true);
                    }}
                  >
                    {/* <img src={item.img} className="img-contact" /> */}
                    <a>{item.title}</a>
                  </div>
                ))}
              </div>
            ))}
      </div>
    </div>
  );
}
