import React from "react";

export default function ModalInfoMySchedule(props) {
  const { isOpen, toggle } = props;
  function saveSchedule() {
    const startTime = props.startEnd.split("-")[0].trim();
    const endTime = props.startEnd.split("-")[1].trim();
    const data = {
      message: "add-schedule",
      data: {
        title: props.title,
        start: new Date(props.date + " " + startTime),
        end: new Date(props.date + " " + endTime),
      },
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }
  function formateDate(e) {
    var date = e.split("/");
    var dateFormated = date[2] + "/" + date[1] + "/" + date[0];

    return dateFormated;
  }
  function shareSchedule() {
    const startTime = props.startEnd.split("-")[0].trim();
    const endTime = props.startEnd.split("-")[1].trim();
    const data = {
      message: "sharing-data",
      data: {
        text: `${props.title}\n\n${formateDate(
          props.date
        )} \n ${startTime}h às ${endTime}h\n\nPara saber mais informações baixe o APP\nhttps://paramount.sistemainteegra.com.br/store`,
      },
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }

  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? "0%" : "100%",
      }}
    >
      <i className="mdi mdi-chevron-left close-menu" onClick={toggle} />

      <i
        className="mdi mdi-share close-menu"
        style={{ right: 0, left: "auto" }}
        onClick={() => shareSchedule()}
      />

      <img src={props.logo} className="img-modal" />

      <div className="limit">
        <div className="div-title">{props.title}</div>
        <div className="div-icons-sponsors">
          {/* <i className="mdi mdi-facebook" style={{ fontSize: 30 }} /> */}
          {props?.instagram && (
            <a
              href={props.instagram}
              target="_blank"
              alt="photo"
              rel="noreferrer"
            >
              <i
                className="mdi mdi-instagram"
                style={{ fontSize: 30, margin: "0 20px" }}
              />
            </a>
          )}
          {props?.twitter && (
            <a
              href={props.twitter}
              target="_blank"
              alt="photo"
              rel="noreferrer"
            >
              <i
                className="mdi mdi-twitter"
                style={{ fontSize: 30, margin: "0 20px" }}
              />
            </a>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
          }}
        >
          <a>
            <i className="mdi mdi-map-marker" />
            {props.local}
          </a>

          <a>
            <i className="mdi mdi-timer" />
            {props.startEnd}
          </a>
        </div>
        {props.description && (
          <div className="div-description-schedule">
            <div className="data-description-schedule">
              <p style={{ fontWeight: "bold", fontSize: 17 }}>Descrição</p>
              <p>{props.description}</p>
            </div>
          </div>
        )}
        <a className="add-schedule" onClick={() => saveSchedule()}>
          Adicionar a agenda
        </a>
      </div>
    </div>
  );
}
