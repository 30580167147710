import axios from "axios";

async function getToken() {
  var config = {
    method: 'POST',
    url: 'https://vidcon.grenadine.co/oauth/token?client_id=2550320f3368e4e7366a729c8a0ad7b712342fcdcb390e1fd2eee86225bb8377&client_secret=250f5b07b605cfe9c0ff08796a111873901f4a4f5ae329710901a87c1abf130d&grant_type=client_credentials',
    headers: {
      'content-type': 'application/json',
    },
  };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((res) => resolve(res))
      .catch((error) => {
        resolve(error);
      });
  });

  // return axios(config)
  //   .then((res) => {
  //     return res;
  //   })
  //   .catch((error) => {
  //     return error;
  //   });
}

async function getAllSpeakers(page = 1, search = '', limit = 50) {
  const token = await getToken();
  const _options = {
    url: `https://vidcon.grenadine.co/pno3axqci5/apis/1/people?page=${page}&search=${search}&per_page=${limit}`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      'Content-Type': 'application/json',
    },
  };

  var config = {
    method: 'post',
    url: 'https://hall-gateway.inteegra.com.br/firebase/genericRequest',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      request: _options,
    },
  };
  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((res) => resolve(res))
      .catch((error) => {
        resolve(error);
      });
  });

  // return axios(config)
  //   .then((response) => {
  //     return response;
  //   })
  //   .catch((error) => {
  //     return error;
  //   });
}

async function getSpeaker(id) {
  const token = await getToken();

  const _options = {
    url: `https://vidcon.grenadine.co/pno3axqci5/apis/1/people/${id}`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      'Content-Type': 'application/json',
    },
  };

  var config = {
    method: 'post',
    url: 'https://hall-gateway.inteegra.com.br/firebase/genericRequest',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      request: _options,
    },
  };
  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((res) => resolve(res))
      .catch((error) => {
        resolve(error);
      });
  });

  // return axios(config)
  //   .then((response) => {
  //     return response;
  //   })
  //   .catch((error) => {
  //     return error;
  //   });
}

async function getAllItems() {
  const token = await getToken();
  const _options = {
    url: `https://vidcon.grenadine.co/pno3axqci5/apis/1/published_items?per_page=300`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      'Content-Type': 'application/json',
    },
  };

  var config = {
    method: 'post',
    url: 'https://hall-gateway.inteegra.com.br/firebase/genericRequest',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      request: _options,
    },
  };
  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((res) => resolve(res))
      .catch((error) => {
        resolve(error);
      });
  });

  // return axios(config)
  //   .then((response) => {
  //     return response;
  //   })
  //   .catch((error) => {
  //     return error;
  //   });
}

async function getAllRooms() {
  const token = await getToken();

  const _options = {
    url: `https://vidcon.grenadine.co/pno3axqci5/apis/1/published_rooms`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token.data.access_token}`,
      'Content-Type': 'application/json',
    },
  };

  var config = {
    method: 'post',
    url: 'https://hall-gateway.inteegra.com.br/firebase/genericRequest',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      request: _options,
    },
  };
  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((res) => resolve(res))
      .catch((error) => {
        resolve(error);
      });
  });
  // return axios(config)
  //   .then((response) => {
  //     return response;
  //   })
  //   .catch((error) => {
  //     return error;
  //   });
}

export { getAllSpeakers, getSpeaker, getAllItems, getAllRooms };
