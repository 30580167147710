import React, { useState } from "react";
// img
import Profile from "../../../assets/images/vidCon/profile.png";
// componentes
import ModalInfoSpeakers from "./modalInfoSpeakers";

export default function Speackers() {
  const [modalSpeakers, setModalSpeakers] = useState(false);

  return (
    <>
      <ModalInfoSpeakers
        isOpen={modalSpeakers}
        toggle={() => setModalSpeakers(!modalSpeakers)}
      />
      <div
        className="div-description-schedule"
        onClick={() => setModalSpeakers(true)}
      >
        <img className="img-speackers" src={Profile} />

        <div className="data-description-schedule">
          <a style={{ fontWeight: "bold", fontSize: 17 }}>Palestrante 1</a>
        </div>
      </div>
    </>
  );
}
