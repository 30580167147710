import React, { useState, useEffect } from "react";
// img
import Profile from "../../../../assets/images/vidCon/profile.png";
// componentes
// import SkeletonModal from "../../../../components/skeleton/modal";
// service
import { getSpeaker } from "../../../../utils/services/index";

export default function ModalInfoSpeakers(props) {
  const { isOpen, toggle, id } = props;
  const [loading, setLoading] = useState(true);
  const [speackerData, setSpeakerData] = useState();

  async function getSpeakerInfo() {
    await getSpeaker(id)
      .then((res) => {
        // console.log("ok", res);
        setSpeakerData(res?.data?.response);
        setLoading(false);
      })
      // .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    if (isOpen) {
      getSpeakerInfo();
    } else {
      // setLoading(true)
    }
  }, [isOpen]);

  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? '0%' : '100%',
      }}
    >
      <i className="mdi mdi-chevron-left close-menu" onClick={toggle} />
      {loading ? (
        <></>
      ) : (
        // <SkeletonModal />
        <>
          <img
            src={speackerData.image.public_image_url}
            style={{ width: '100%' }}
          />

          <div className="limit">
            <div
              className="div-title"
              style={{ fontWeight: 'bold', fontSize: 25 }}
              // onClick={() => console.log(speackerData)}
            >
              {speackerData.full_publication_name}
            </div>
            <a
              dangerouslySetInnerHTML={{ __html: speackerData.edited_bio.bio }}
            />

            <div className="div-icons-sponsors">
              {speackerData.edited_bio.linkedin && (
                <a
                  href={speackerData.edited_bio.linkedin}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <i
                    className="mdi mdi-linkedin"
                    style={{ fontSize: 30, margin: '0 10px' }}
                  />
                </a>
              )}

              {speackerData.edited_bio.facebook && (
                <a
                  href={speackerData.edited_bio.facebook}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <i
                    className="mdi mdi-facebook"
                    style={{ fontSize: 30, margin: '0 10px' }}
                  />
                </a>
              )}

              {speackerData.edited_bio.instagram && (
                <a
                  href={speackerData.edited_bio.instagram}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <i
                    className="mdi mdi-instagram"
                    style={{ fontSize: 30, margin: '0 10px' }}
                  />
                </a>
              )}

              {speackerData.edited_bio.twitterinfo && (
                <a
                  href={speackerData.edited_bio.twitterinfo}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <i
                    className="mdi mdi-twitter"
                    style={{ fontSize: 30, margin: '0 10px' }}
                  />
                </a>
              )}

              {speackerData.edited_bio.twitch && (
                <a
                  href={speackerData.edited_bio.twitch}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="mdi mdi-twitch"
                    style={{ fontSize: 30, margin: '0 10px' }}
                  />
                </a>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
