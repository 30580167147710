/* eslint-disable no-undef */
import React, { useState } from "react";
// componentes
import BottomMenu from "./bottomMenu";
import MenuSchedule from "./menuSchedule";
import MenuLineup from "./menuLineup";
import MySchedule from "../mySchedule";

export default function Schedule() {
  const [step, setStep] = useState("a");
  return (
    <section style={{ paddingBottom: 100 }}>
      {step === "a" && <MenuSchedule />}
      {step === "b" && <MenuLineup />}
      {step === "c" && <MySchedule />}
      <BottomMenu setStep={setStep} step={step} />
    </section>
  );
}
