import { Collapse } from "reactstrap";

export var faqList = [
  {
    title: 'Onde & Quando',
    question: [
      {
        title: 'Onde e quando a VidCon São Paulo 2023 acontecerá?',
        data: (col1) => (
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  A VidCon São Paulo 2023 2023 acontecerá de 7 a 9 de julho de
                  2023 no São Paulo Expo.
                </p>
                <p>
                  <strong className="text-dark">
                    São Paulo Expo – Centro de Convenções:
                  </strong>{' '}
                  Pavilhão 1. Endereço: Rodovia Imigrantes, s/n, km 1,5, Vila
                  Água Funda, São Paulo/SP, CEP: 04329-900.
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Haverá VidCons em outros locais?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Sim! Veja o nosso site para saber mais informações sobre as
                VidCons que acontecerão em outros países
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Qual é a programação para cada dia da VidCon São Paulo 2023?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Para ter uma ideia geral da programação de cada dia da VidCon
                São Paulo 2023, confira nossos{' '}
                <a
                  href="https://www.vidcon.com/sao-paulo/pt/agenda-at-a-glance/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Horários de Funcionamento
                </a>
                ! Também teremos uma agenda completa disponível mais perto do
                evento com detalhes sobre cada sessão, apresentação, workshop,
                entre outros.
              </div>
            </div>
          </Collapse>
        ),
      },
    ],
  },
  {
    title: 'Ingressos & Credenciamento',
    question: [
      {
        title: 'Onde posso comprar ingressos para a VidCon São Paulo 2023?',
        data: (col1) => (
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Os ingressos estão disponíveis para compra no nosso{' '}
                <a
                  href="https://www.vidcon.com/sao-paulo/pt/tickets/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  site
                </a>
                !
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Qual tipo de ingresso eu posso comprar?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Para que você tenha a melhor experiência, a VidCon São Paulo
                  2023 tem dois tipos de Ingressos pensados para seus diferentes
                  públicos. Confira abaixo qual tem mais a ver com você!
                </p>
                <p>
                  O{' '}
                  <a
                    href="https://www.vidcon.com/sao-paulo/pt/community-track/"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    Ingresso Comunidade
                  </a>{' '}
                  é para aqueles que amam o universo dos conteúdos online e seus
                  criadores. São dois dias (sábado e domingo) com muito
                  conteúdo, shows, painéis, apresentações, workshops e tudo isso
                  pertinho dos seus criadores favoritos. Os ingressos Comunidade
                  te dão acesso ao site para agendamento do{' '}
                  <a
                    href="https://www.vidcon.com/sao-paulo/pt/meet-greets/"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    Meet & Greet
                  </a>{' '}
                  (por ordem de chegada, vagas sujeitas a disponibilidade).
                </p>
                <p>
                  Se você não quer perder o evento, mas só pode participar de um
                  dia da VidCon São Paulo, você pode adquirir o ingresso
                  Comunidade para um dia que oferece acesso a todo o conteúdo da
                  programação Comunidade oferecido naquele dia, sábado ou no
                  domingo.
                </p>
                <p>
                  O{' '}
                  <a
                    href="https://www.vidcon.com/sao-paulo/pt/industry-track/"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    Ingresso Indústria
                  </a>{' '}
                  é para aqueles que querem se aprofundar no universo dos
                  conteúdos digitais em qualquer nível profissional. Se você faz
                  parte de uma produtora, trabalha com gerenciamento de
                  talentos, marketing, desenvolvimento de softwares ou é um
                  empresário, este pode ser o caminho certo para você. O
                  ingresso Indústria lhe dará acesso a workshops, painéis
                  exclusivos, plataformas inovadoras e demonstrações de
                  produtos. Haverá também treinamentos e oportunidades de
                  networking com outros profissionais no mercado. O ingresso
                  Indústria também dá acesso a todo o conteúdo da programação
                  Comunidade, incluindo acesso à plataforma de agendamento do{' '}
                  <a
                    href="https://www.vidcon.com/sao-paulo/pt/meet-greets/"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    Meet & Greet
                  </a>{' '}
                  (por ordem de chegada, vagas sujeitas a disponibilidade).
                </p>
                <strong className="text-dark">
                  Crianças com 5 anos ou menos, na data do evento, não precisam
                  ter seu próprio ingresso e podem participar de todas as
                  atividades oferecidas pelo tipo de ingresso de seus
                  pais/responsáveis.
                </strong>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Você não poderá participar de todos os dias? A VidCon tem uma opção de ingresso para um dia!',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Você pode conferir os valores de todos os ingressos para a
                VidCon São Paulo 2023 acessando a aba ingressos do site da
                VidCon São Paulo ou acessando o site da Go Dream e clicando no
                evento VidCon São Paulo 2023.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Como sei se a meia-entrada vale pra mim?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Confira{' '}
                <a
                  href="https://godream.com.br/meia"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  AQUI
                </a>{' '}
                se a lei nº 12.933/2013 se aplica a você e quais documentos
                devem ser apresentados na entrada do evento.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Por quanto tempo os ingressos estarão disponíveis para venda?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Os ingressos estarão disponíveis para venda em nosso site até se
                esgotarem. Se eles não se esgotarem, você pode comprar seu(s)
                ingresso(s) durante o evento{' '}
                <a
                  href="https://godream.com.br/events/vidcon-sao-paulo-2023/tickets"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  aqui
                </a>
                .
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Quando é o último dia em que posso cancelar meu pedido?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Para solicitar o cancelamento de um pedido, entre em contato
                  com o atendimento@godream.com.br.
                </p>
                <p>
                  <strong className="text-dark">Importante:</strong> reembolsos
                  de ingressos só serão aceitos se solicitados no prazo de 7
                  dias corridos após a efetivação do pedido e desde que seja a
                  menos de 48 horas antes do início do evento.
                </p>
                <p>
                  Se o pedido foi pago com cartão de crédito o estorno será
                  realizado no mesmo cartão utilizado sendo creditado em sua
                  próxima fatura ou subsequente.
                </p>
                <p>
                  Caso o pedido tenha sido pago com boleto bancário o estorno
                  será realizado em conta bancária do responsável pelo pedido em
                  até 15 dias úteis após informada. A solicitação do estorno
                  deve ser realizada por escrito através do e-mail
                  atendimento@godream.com.br informando o número do pedido,
                  nome, CPF e e-mail do responsável utilizado para realizar o
                  pedido.
                </p>
                <p>
                  <strong className="text-dark">
                    Atenção: APENAS o titular do pedido pode solicitar um
                    reembolso ou cancelar o pedido de compra.
                  </strong>
                </p>
                <p>
                  Caso o pedido seja cancelado o valor integral do pedido será
                  devolvido.
                </p>
                <p>
                  Os ingressos já adquiridos não poderão ser transferidos para
                  outra data do evento.
                </p>
                <p>
                  O cancelamento do ingresso também cancela automaticamente a
                  sessão de Meet & Greet agendada.
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Como cancelo um ingresso específico que comprei?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Os pedidos de cancelamento são apenas para todos os ingressos de
                uma ordem de compra. Isso significa que um ingresso específico
                não pode ser cancelado. Para cancelar um pedido, envie um e-mail
                para atendimento@godream.com.br com o número do pedido, nome,
                CPF e e-mail de quem fez o pedido. Importante: APENAS o titular
                do pedido pode solicitar um reembolso. Para detalhes adicionais,
                consulte nossa{' '}
                <a
                  href="https://godream.com.br/cancellation"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Política de Reembolso
                </a>
                .
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Posso modificar o nome no(s) meu(s) ingresso(s)?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Depois de fazer um pedido, não é mais possível alterar os nomes
                dos titulares dos ingressos. Caso sejam necessárias alterações,
                deve ser feito um pedido de cancelamento de compra e, em
                seguida, os ingressos devem ser comprados novamente. Lembre-se
                que a venda de ingressos depende da disponibilidade de
                ingressos, e o reembolso só será aceito se solicitado em até
                sete dias corridos após a efetivação do pedido e 48 horas antes
                do início do evento.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Como cancelo meus ingressos?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Se você deseja cancelar seu pedido de ingresso(s), entre em
                contato com atendimento@godream.com.br com o número do pedido,
                nome, CPF e e-mail de quem fez o pedido, que pode ser encontrado
                no e-mail de confirmação do pedido ou acessando sua conta Go
                Dream. Importante: APENAS o titular do pedido pode solicitar um
                reembolso. Para mais informações, consulte nossa{' '}
                <a
                  href="https://godream.com.br/cancellation"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  {' '}
                  Política de Reembolso
                </a>
                .
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Não consigo encontrar meu e-mail de confirmação com meus ingressos?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Se você for o titular do pedido e não conseguir localizar o
                e-mail de confirmação do pedido, você poderá acessar todos os
                ingressos fazendo login na sua conta Go Dream{' '}
                <a
                  href="https://godream.com.br/auth/login?returnUrl=https:%2F%2Fgodream.com.br%2F"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  aqui
                </a>
                . Se você não recebeu seu ingresso ou não o encontrou em sua
                conta, mesmo após a confirmação da compra, entre em contato
                atendimento@godream.com.br. Se outra pessoa comprou/pediu o seu
                ingresso, certifique-se de que ela encaminhe a confirmação do
                pedido para você ou que ela faça o download e envie o seu
                ingresso.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Posso trocar o meu ingresso por outro tipo de ingresso para a VidCon São Paulo 2023?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Se a compra do seu ingresso já foi confirmada, não é possível
                trocá-lo. Se quiser trocá-lo mesmo assim, você pode solicitar o
                cancelamento do seu pedido e depois comprar o(s) seu(s)
                ingresso(s) novamente. Informamos que a venda depende da
                disponibilidade do ingresso e que o reembolso só será aceito se
                solicitado em até 7 dias corridos após a realização do pedido e
                48 horas antes do início do evento.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'O que meu ingresso me dá acesso na VidCon São Paulo 2023?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Você pode conferir mais informações sobre o que cada tipo de
                ingresso oferece acesso visitando a nossa página{' '}
                <a
                  href="https://www.vidcon.com/sao-paulo/pt/tickets/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Comprar Ingressos
                </a>{' '}
                ou conferindo a programação completa, que estará disponível em
                nosso site próximo ao evento.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Quando/onde/como retiro minha credencial e pulseira quando chegar na VidCon São Paulo 2023?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Você só poderá acessar o evento apresentando um documento de
                  identidade válido com foto e o QR Code do ingresso, que
                  enviamos por e-mail junto com a confirmação da compra. Você
                  também pode acessar seu pedido na aba Meus Pedidos do nosso{' '}
                  <a
                    href="https://godream.com.br/auth/login?returnUrl=%2Faccount%2Forders"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    {' '}
                    site de venda de ingressos
                  </a>
                  .
                </p>
                <p>
                  Se o seu ingresso for meia-entrada, você deverá apresentar
                  também o comprovante necessário. Consulte{' '}
                  <a
                    href="https://godream.com.br/meia"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    este site
                  </a>{' '}
                  para obter mais informações sobre as políticas de
                  meia-entrada.
                </p>
                <p>
                  Ao chegar no São Paulo Expo, dirija-se à entrada do evento no
                  Pavilhão 1. Para resgatar sua credencial, apresente o QR Code
                  do seu ingresso e um documento de identidade válido na área de
                  credenciamento no local.
                </p>
                <p style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong className="text-dark">Importante:</strong>
                  <a>
                    - Cada participante deve resgatar presencialmente sua
                    própria credencial; ninguém pode pegar sua credencial em seu
                    nome.
                  </a>
                  <a>
                    - Todos os participantes da VidCon devem ter sua credencial
                    e pulseira em mãos o tempo todo. Essa medida tem como
                    objetivo verificar e garantir que todos os participantes
                    estão autorizados a entrar no evento ou dentro de uma área
                    específica que tenha acesso controlado.
                  </a>
                  <a>
                    - Você tem até 1 hora antes do horário de encerramento para
                    entrar na VidCon São Paulo. Após esse período, seu ingresso
                    perderá a validade, ou seja, não será possível acessar o
                    evento. A prioridade da VidCon é a segurança e a saúde de
                    nossos participantes, patrocinadores, palestrantes,
                    criadores e funcionários. Portanto, todos os participantes
                    serão obrigados a cumprir os nossos protocolos COVID-19. A
                    recusa em cooperar com esses protocolos no local resultará
                    na expulsão sem reembolso. Confira nossa Política Covid-19.
                  </a>
                  <a>
                    - Cada participante receberá uma credencial e uma pulseira
                    que deverão ser usadas o tempo todo dentro do evento. As
                    credenciais perdidas ou danificadas, assim como as
                    pulseiras, estão sujeitas a uma taxa de substituição de R$
                    100,00.
                  </a>
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Perdi minha credencial, o que eu faço?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Sua credencial deve ser usada em todos os momentos durante o
                evento. As credenciais podem ser substituídas por uma taxa de R$
                100,00 por credencial na área de credenciamento (para troca da
                credencial e pulseira é cobrada uma taxa de R$ 150,00).
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Como posso comprar mais de 6 ingressos Indústria?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Se você deseja comprar mais de 6 ingressos para o dia de
                  Indústria, basta seguir o passo-a-passo abaixo.
                </p>

                <p style={{ display: 'flex', flexDirection: 'column' }}>
                  <a>
                    1) Entre em contato através do SAC
                    atendimento@godream.com.br informando o título do email
                    “Quero comprar mais de 6 ingressos Indústria na VidCon”. Por
                    favor descreva no corpo da mensagem quantos ingressos
                    deseja.
                  </a>
                  <a>
                    2) A Go Dream enviará um link de pagamento no valor total do
                    pedido + taxa de conveniência.{' '}
                    <strong className="text-dark">
                      Importante ressaltar que esse link tem a validade de 48h e
                      o pagamento é confirmado em até 72h após o vencimento.
                    </strong>
                  </a>
                  <a>
                    3) Após esse período, a Go Dream envia a quantidade de
                    códigos de resgate de acordo com a quantidade de ingressos
                    adquiridos;
                  </a>
                  <a>
                    4) O responsável pelo pedido distribuirá um código por
                    portador que deverá efetuar o resgate na Go Dream conforme o
                    fluxo de compra. Com esses códigos, cada portador faz o seu
                    cadastro e garante a isenção no pagamento no final do
                    processo.
                  </a>
                </p>
                <p>
                  Feito isso, cada portador será responsável pela emissão do seu
                  ingresso bem como agendamento do meet & greet. Para mais
                  informações sobre o meet & greet acesse{' '}
                  <a
                    href="https://www.vidcon.com/sao-paulo/pt/meet-greets/"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    esta página
                  </a>
                  .
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
    ],
  },
  {
    title: 'Meet & Greet',
    question: [
      {
        title:
          'Como posso saber quais meet & greets acontecerão na VidCon Sao Paulo 2023?',
        data: (col1) => (
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Você pode conferir quais sessões de meet & greet acontecerão na
                nossa{' '}
                <a
                  href="https://www.vidcon.com/sao-paulo/pt/meet-greets/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  página sobre o Meet & Greet
                </a>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Já posso agendar minha sessão de meet & greet?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  O site para agendamento das sessões de Meet & Greet está
                  aberto durante os dias 6 a 20 de junho exclusivamente para
                  quem comprou o ingresso Comunidade 2 Dias e/ou Indústria. A
                  partir do dia 21, quem comprou ingresso para um dia de evento
                  (sábado ou domingo) também poderá fazer seu login no site e se
                  inscrever para sessões Meet & Greet. O site permanecerá aberto
                  até o último dia do evento (9 de julho) ou até que as sessões
                  se esgotem. Cada portador de um ingresso pode agendar uma
                  sessão de Meet & Greet.
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Importante:</span> todas
                  as sessões de meet & greet tem um número de vagas limitadas e
                  poderão ser agendadas por ordem de chegada e conforme
                  disponibilidade. A disponibilidade de vagas para as sessões
                  não é garantida e a compra de um ingresso não garante o acesso
                  ao meet & greet.
                </p>
                <p>
                  Você pode encontrar instruções detalhadas sobre como agendar
                  uma sessão de meet & greet acessando o artigo da nossa FAQ
                  “Como posso agendar uma sessão de meet & greet na VidCon São
                  Paulo?”.
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Como posso agendar minha sessão de Meet & Greet na VidCon São Paulo 2023?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>Para agendar sua sessão, siga os passos abaixo:</p>
                <p>
                  <ol>
                    <li>
                      Acesse{' '}
                      <a
                        href="https://godream.com.br"
                        target="_blank"
                        rel="noreferrer"
                      >
                        godream.com.br.
                      </a>{' '}
                    </li>
                    <li>
                      Clique em “EU QUERO” no card “Meet & Greet VidCon São
                      Paulo 2023”.
                    </li>
                    <li>
                      Faça login com o endereço de e-mail e a senha que você
                      usou quando comprou seus ingressos.
                    </li>
                    <li>Selecione o criador que deseja conhecer.</li>
                    <li>
                      Selecione o dia e horário de sua preferência (se aplicável
                      — nem todas as sessões terão mais de uma opção). Note que
                      o dia da sessão que você agendar deve estar de acordo com
                      o dia em que seu ingresso dá acesso ao evento. Ex.: se o
                      seu bilhete te dá acesso apenas ao sábado, a sua sessão
                      deve ser marcada para esse mesmo dia.
                    </li>
                    <li>
                      Selecione o nome da pessoa que participará da sessão de
                      Meet & Greet. Cada portador de um ingresso para a VidCon
                      São Paulo 2023 pode se inscrever para uma sessão de meet &
                      greet.
                    </li>
                    <li>Revise seu agendamento e confirme.</li>
                    <li>
                      Pronto! Você receberá um e-mail de confirmação com o(s) QR
                      Code(s) para acesso à sessão de Meet & Greet agendada.
                      Você também pode acessar seus() QR Code(s) através da
                      <a
                        href="https://godream.com.br/account/profile"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        página “Meus pedidos” no site da Go Dream.
                      </a>
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      // {
      //   title: 'Eu já posso agendar o Meet & Greet?',
      //   data: (col) => (
      //     <Collapse isOpen={col} className="accordion-collapse">
      //       <div className="accordion-body">
      //         <div className="text-muted">
      //           <p>
      //             O site para agendamento das sessões de meet & greet será
      //             aberto cerca de um mês antes do evento e só poderá ser
      //             acessado pelo pessoal que comprou ingresso para a VidCon São
      //             Paulo. Os compradores dos ingressos para 2 dias ou mais de
      //             evento (Ingresso Comunidade 2 Dias e Ingresso Indústria)
      //             poderão acessar e fazer seu agendamento primeiro, com
      //             exclusividade, de acordo com as sessões disponíveis. Duas
      //             semanas depois, os compradores dos ingressos para um dia de
      //             evento (Comunidade Sábado, Comunidade Domingo e Combo
      //             Comunidade 1 Dia Família) poderão fazer login e agendar sua
      //             sessão de meet & greet. A disponibilidade de vagas para as
      //             sessões não é garantida e a compra de um ingresso não garante
      //             o acesso ao meet & greet.
      //           </p>
      //           <p>
      //             Fique de olho no site da VidCon São Paulo, nas redes sociais e
      //             na sua caixa de e-mail para ficar saber quando o site será
      //             aberto para agendamento!
      //           </p>
      //         </div>
      //       </div>
      //     </Collapse>
      //   ),
      // },
      {
        title:
          'Vou conseguir conhecer e tirar uma foto com o meu criador favorito?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Não podemos garantir que você vai conhecer ou tirar uma foto
                  com seu criador de conteúdo favorito, mas você pode ter essa
                  chance com um dos criadores da VidCon São Paulo 2023 agendando
                  um Meet & Greet. Para ter a chance de agendar uma sessão, você
                  deve:
                </p>
                <p style={{ display: 'flex', flexDirection: 'column' }}>
                  <a>° Comprar um ingresso para a VidCon São Paulo 2023. </a>
                  <a>
                    ° Agendar o Meet & Greet através da nossa plataforma, que
                    abrirá cerca de 1 mês antes do evento. Fique ligado no site
                    e nas redes sociais da VidCon São Paulo e na caixa de
                    entrada do seu e-mail para saber assim que o site de
                    agendamento estiver aberto!
                  </a>
                </p>
                <p>
                  <strong className="text-dark">Importante:</strong>
                </p>
                <p style={{ display: 'flex', flexDirection: 'column' }}>
                  <a>
                    ° Os compradores dos ingressos para 2 dias ou mais de evento
                    (Ingresso Comunidade 2 Dias e Ingresso Indústria) poderão
                    acessar e fazer seu agendamento primeiro, com exclusividade,
                    de acordo com as sessões disponíveis. Duas semanas depois,
                    os compradores dos ingressos para um dia de evento
                    (Comunidade Sábado, Comunidade Domingo e Combo Comunidade 1
                    Dia Família) poderão fazer login e agendar sua sessão de
                    meet & greet.{' '}
                  </a>
                  <a>
                    ° Todas as sessões de meet & greet tem um número de vagas
                    limitadas e poderão ser agendadas por ordem de chegada e
                    conforme disponibilidade.
                  </a>
                  <a>
                    ° Só será possível acessar a plataforma de agendamento com
                    as credenciais do titular do pedido de compra cadastrado no
                    site da Go Dream (e-mail e senha).
                  </a>
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Posso acessar a área de Meet & Greet junto dos meus amigos?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Só será possível acessar a plataforma de agendamento com as
                credenciais do titular do pedido de compra cadastrado no site da
                Go Dream (e-mail e senha).
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Posso trocar minha sessão de Meet & Greet com um amigo?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Você não pode trocar sua sessão de Meet & Greet com seu(s)
                  amigo(s), elas são intransferíveis assim como os ingressos da
                  VidCon São Paulo 2023. Mas se você quiser trocar de sessão,
                  pode cancelar a sessão que agendou em nossa plataforma de Meet
                  & Greet e agendar outra sessão.
                </p>
                <p>
                  <strong className="text-dark">Importante:</strong>
                </p>
                <p>
                  ° Todas as sessões de meet & greet tem um número de vagas
                  limitadas e poderão ser agendadas por ordem de chegada e
                  conforme disponibilidade.
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Posso entrar na área de Meet & Greet junto da criança que estou acompanhando?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Sim, você pode acessar a área de meet & greet com a
                  criança/adolescente que você está acompanhando desde que:
                </p>
                <p style={{ display: 'flex', flexDirection: 'column' }}>
                  <a>
                    ° A criança/adolescente tenha agendado uma sessão Meet &
                    Greet na nossa plataforma de agendamento.
                  </a>
                  <a>
                    ° Você é o pai ou responsável legal da criança/adolescente e
                    comprou um ingresso para o VidCon São Paulo 2023
                    correspondente ao(s) mesmo(s) dia(s) que ela(s) tem acesso.
                  </a>
                </p>
                <p>
                  <strong className="text-dark">Importante:</strong>
                </p>
                <p style={{ display: 'flex', flexDirection: 'column' }}>
                  <a>
                    ° Os participantes com 16 anos completos ou mais não precism
                    estar acompanhados dos pais ou responsáveis.
                  </a>
                  <a>
                    ° Você pode acessar a área de Meet & Greet para acompanhar a
                    criança/adolescente, mas se não agendou uma sessão não
                    poderá participar da sessão de Meet & Greet.
                  </a>
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Se eu cancelar meu ingresso para a VidCon São Paulo 2023, o que acontecerá com a sessão Meet & Greet que agendei?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Depois de cancelar o pedido do ingresso, as sessões Meet & Greet
                vinculadas a esse mesmo pedido são automaticamente canceladas.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'O site para agendamento do Meet & Greet negou meu acesso.',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Lembre-se de que você só poderá acessar o site e agendar uma
                sessão de Meet & Greet com o login e senha do titular do pedido
                cadastrados no site da Go Dream. Vale lembrar que, o pessoal que
                comprou o ingresso Comunidade 2 Dias e/ou Indústria poderão
                acessar o site e fazer o agendamento de acordo com as sessões
                disponíveis, com exclusividade, durante os dias 6 a 20 junho. O
                login estará liberado para todos os tipos de ingresso, incluindo
                o pessoal que comprou ingresso para um dia de evento (sábado ou
                domingo), entre 21 de junho e 9 de julho. Se você comprou um
                ingresso para a VidCon São Paulo e acessou o site de acordo com
                as condições acima, mas mesmo assim teve seu acesso negado entre
                em contato com atendimento@godream.com.br que o time Go Dream
                irá rapidamente te ajudar.
              </div>
              <p
                className="text-muted"
                style={{ fontWeight: 'bold', marginTop: '10px' }}
              >
                Vale lembrar:{' '}
              </p>
              <p className="text-muted">
                <ul>
                  <li>
                    O site estará aberto para agendamento do dia 6 de junho ao
                    dia 9 de julho.
                  </li>
                  <li>
                    {' '}
                    Todas as sessões de Meet & Greet tem um número de vagas
                    limitadas e poderão ser agendadas por ordem de chegada e
                    conforme disponibilidade.
                  </li>
                </ul>
              </p>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Como posso cancelar ou reagendar uma sessão de Meet & Greet?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <ol style={{ paddingLeft: 10 }}>
                  <li> Faça login no site da Go Dream.</li>
                  <li> Acesse a página “Meus Pedidos”.</li>
                  <li>
                    {' '}
                    Clique em “Visualizar Pedido” no card corresponde ao seu
                    pedido de agendamento do Meet & Greet.
                  </li>
                  <li>
                    {' '}
                    Abaixo do código QR clique no botão “Reagendar meu pedido”.
                  </li>
                  <li>
                    {' '}
                    Em seguida, clique em “Confirmar Reagendamento”. Pronto, sua
                    sessão foi cancelada!
                  </li>
                  <li>
                    {' '}
                    Caso deseje agendar outra sessão, clique em “Agendar Nova
                    Sessão”.
                  </li>
                </ol>
                <p style={{ fontWeight: 'bold', marginTop: '10px' }}>
                  {' '}
                  Importante:{' '}
                </p>
                <ul>
                  <li>
                    {' '}
                    Se você tem outros agendamentos no seu pedido atrelados a
                    outros ingressos, ao clicar no botão “Reagendar meu pedido”
                    localizado abaixo de um dos QR-codes, você cancela aquela
                    sessão específica atrelada aquele ingresso.
                  </li>
                  <li>
                    {' '}
                    Todas as sessões de Meet & Greet tem um número de vagas
                    limitadas e são agendadas por ordem de chegada conforme
                    disponibilidade. Portanto se você cancelar sua sessão, não
                    há garantia de que poderá reagendá-la.
                  </li>
                </ul>
              </div>
            </div>
          </Collapse>
        ),
      },
      // {
      //   title: "Já posso agendar minha sessão de meet & greet?",
      //   data: (col) => (
      //     <Collapse isOpen={col} className="accordion-collapse">
      //       <div className="accordion-body">
      //         <div className="text-muted">
      //           <p>
      //             O site para agendamento das sessões de Meet & Greet está
      //             aberto durante os dias 6 a 20 de junho exclusivamente para
      //             quem comprou o ingresso Comunidade 2 Dias e/ou Indústria. A
      //             partir do dia 21, quem comprou ingresso para um dia de evento
      //             (sábado ou domingo) também poderá fazer seu login no site e se
      //             inscrever para sessões Meet & Greet. O site permanecerá aberto
      //             até o último dia do evento (9 de julho) ou até que as sessões
      //             se esgotem. Cada portador de um ingresso pode agendar uma
      //             sessão de Meet & Greet.
      //           </p>
      //           <p>
      //             <span style={{ fontWeight: "bold" }}>Importante:</span> todas
      //             as sessões de meet & greet tem um número de vagas limitadas e
      //             poderão ser agendadas por ordem de chegada e conforme
      //             disponibilidade. A disponibilidade de vagas para as sessões
      //             não é garantida e a compra de um ingresso não garante o acesso
      //             ao meet & greet.
      //           </p>
      //           <p>
      //             Você pode encontrar instruções detalhadas sobre como agendar
      //             uma sessão de meet & greet acessando o artigo da nossa FAQ
      //             “Como posso agendar uma sessão de meet & greet na VidCon São
      //             Paulo?”.
      //           </p>
      //         </div>
      //       </div>
      //     </Collapse>
      //   ),
      // },
    ],
  },
  {
    title: 'Pais & Acompanhantes',
    question: [
      {
        title:
          'Pais ou acompanhantes precisam de ingresso para a VidCon São Paulo?',
        data: (col1) => (
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Se você comparecerá ao evento com um menor de 16 anos,
                  precisará do seu próprio ingresso para participar da VidCon
                  São Paulo. Os responsáveis que estiverem acompanhando alguém
                  com 16 anos ou mais só precisam adquirir um ingresso se
                  desejarem participar da programação e das atividades da VidCon
                  São Paulo com sua criança/adolescente. Consulte nossa Política
                  de acompanhantes abaixo para obter mais informações:
                </p>
                <p>
                  <strong className="text-dark">
                    Crianças com até 5 anos:
                  </strong>{' '}
                  crianças com 5 anos ou menos, até a data do evento, não
                  precisam de ingresso próprio para participar da VidCon São
                  Paulo desde que acompanhadas dos pais ou responsável legal, e
                  que sejam capazes de participar de todas as atividades em que
                  seus pais ou acompanhantes participarão de acordo com o que
                  seu ingresso oferece acesso, incluindo o Meet & Greet
                  (agendamento por ordem de chegada)!
                </p>
                <p>
                  <strong className="text-dark">
                    Crianças entre 6 e 15 anos:
                  </strong>{' '}
                  crianças entre 6 e 15 anos devem estar sempre acompanhadas por
                  um adulto (maior de 18 anos). O adulto deve ter um ingresso
                  que dê acesso aos mesmos dias em que a criança estará
                  presente.
                </p>
                <p>
                  <strong className="text-dark">
                    Crianças com 16 anos ou mais:
                  </strong>{' '}
                  crianças com 16 anos ou mais não precisam estar acompanhadas
                  se os pais/responsáveis permitirem. Se um pai/responsável
                  quiser acompanhar seu filho durante o evento, ele precisará de
                  um ingresso para a VidCon São Paulo 2023. Adultos sem ingresso
                  não terão acesso a nenhum painel, Expo Hall, shows, etc.{' '}
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Podem dois responsáveis diferentes usarem o mesmo ingresso para dias diferentes?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Não, as credenciais da VidCon são intransferíveis e só podem ser
                utilizadas pelo participante cujo nome consta no ingresso
                correspondente.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Não poderei acompanhar o meu filho ou a criança pela qual seria responsável, como transfiro meu ingresso para outro acompanhante?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Os ingressos para a VidCon São Paulo 2023 são nominais e
                intransferíveis, portanto não é possível transferir seu ingresso
                para outro acompanhante. Você pode cancelar o seu pedido
                anterior e comprar um novo ingresso nominal à nova pessoa que
                acompanhará a criança/adolescente. Veja mais informa;óes sobre
                como cancelar o seu pedido de compra{' '}
                <a
                  href="https://www.vidcon.com/sao-paulo/pt/faq/how-do-i-cancel-my-tickets/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  aqui
                </a>
                .
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Posso dar o meu Meet & Greet para a criança que estou acompanhando?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                As sessões de Meet & Greet são intransferíveis. Somente os
                participantes que agendaram uma sessão de Meet & Greet através
                da nossa plataforma de agendamento terão permissão para
                participar da sessão específica que agendaram. Se você estiver
                acompanhando uma criança que agendou uma sessão de Meet & Greet,
                fique à vontade para acompanhá-la e esperá-la enquanto ela
                participa da sessão.
              </div>
            </div>
          </Collapse>
        ),
      },
    ],
  },
  {
    title: 'Hospitalidade & Viagem',
    question: [
      {
        title: 'Como posso encontrar um hotel para ficar?',
        data: (col1) => (
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Confira a nossa página{' '}
                <a
                  href="https://www.vidcon.com/sao-paulo/pt/hotels-travel/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hospitalidade & Viagem
                </a>{' '}
                no site para fazer sua reserva no hotel Comfort Nova Paulista
                com desconto exclusivo.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Como posso chegar à VidCon São Paulo/Meu hotel?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Informações sobre os aeroportos mais próximos podem ser
                encontradas{' '}
                <a
                  href="https://cidadedesaopaulo.com/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  este site
                </a>
                . Existe uma variedade de serviços de van, aluguel de carros e
                transporte público para ajudá-lo a chegar de um lugar a outro.
                Confira nossa página de Hospitalidade & Viagem para mais
                informações.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'O que mais posso fazer na região?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Existe uma variedade de coisas a fazer durante a sua visita à
                São Paulo! Confira{' '}
                <a
                  href="https://cidadedesaopaulo.com/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  este site
                </a>{' '}
                para mais informações.
              </div>
            </div>
          </Collapse>
        ),
      },
    ],
  },
  {
    title: 'Termos & Políticas',
    question: [
      {
        title: 'Qual é o Código de Conduta da VidCon São Paulo?',
        data: (col1) => (
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Nossa prioridade é que a VidCon seja um local seguro e inclusivo
                para os participantes, funcionários, patrocinadores, criadores e
                todos os envolvidos no evento. Por favor, confira o nosso{' '}
                <a
                  href="https://www.vidcon.com/sao-paulo/pt/code-of-conduct/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Código de Conduta
                </a>{' '}
                antes de ir ao evento. Ao comprar um ingresso para a VidCon São
                Paulo, você automaticamente aceita os nossos termos e políticas.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'A VidCon possui um Dress Code?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Queremos que você se sinta confortável e livre para expressar
                sua individualidade! Dito isso, pedimos que qualquer fantasia ou
                peça de roupa que possa ser usada ou confundida com armas seja
                deixada em casa. Além disso, roupas que ocultam completamente o
                rosto podem não ser permitidas (por exemplo, uma peça de
                fantasia que inclua uma máscara facial). Isso não se aplica a
                participantes cujas roupas façam parte de práticas religiosas ou
                culturais ou a qualquer pessoa que use máscara facial de acordo
                com as diretrizes de segurança do COVID-19.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Qual a idade mínima para comparecer à VidCon São Paulo sem um acompanhante ou responsável?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  A VidCon é um evento para todas as idades, mas os
                  participantes menores de 16 anos devem estar sempre
                  acompanhados por um adulto com ingresso (maior de 18 anos).
                  Consulte nossa Política de Acompanhantes abaixo para obter
                  mais informações:
                </p>
                <p>
                  <strong className="text-dark">
                    Crianças com até 5 anos:
                  </strong>{' '}
                  crianças com 5 anos ou menos, até a data do evento, não
                  precisam de ingresso próprio para participar da VidCon São
                  Paulo desde que acompanhadas dos pais ou responsável legal, e
                  que sejam capazes de participar de todas as atividades em que
                  seus pais ou acompanhantes participarão de acordo com o que
                  seu ingresso oferece acesso, incluindo o Meet & Greet
                  (agendamento por ordem de chegada)!
                </p>
                <p>
                  <strong className="text-dark">
                    Crianças entre 6 e 15 anos:
                  </strong>{' '}
                  crianças/adolescentes entre 6 e 15 anos devem estar sempre
                  acompanhadas de um adulto (maior de 18 anos). O adulto deve
                  ter um ingresso que dê acesso ao(s) mesmo(s) dia(s) que a
                  criança/adolescente irá participar.
                </p>
                <p>
                  <strong className="text-dark">
                    Crianças com 16 anos ou mais:
                  </strong>{' '}
                  crianças com 16 anos ou mais não precisam estar acompanhadas,
                  se os pais/responsáveis permitirem. Se um pai/responsável
                  quiser acompanhar seu filho durante o evento, ele precisa de
                  um ingresso para a VidCon São Paulo que corresponda ao(s)
                  mesmo(s) dia(s) em que seu filho/adolescente estiver
                  participando do evento. Adultos sem ingresso não terão acesso
                  a nenhum dos painéis, Expo Hall, shows, etc.
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Quais serão os protocolos COVID-19 do evento?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                A prioridade da VidCon é a segurança e saúde de nossos
                participantes, patrocinadores, palestrantes, criadores e
                funcionários. Estamos trabalhando com nossos parceiros de
                produção e com o São Paulo Expo para garantir que a VidCon São
                Paulo 2023 seja uma celebração vibrante, divertida, educativa e
                segura para todos. Sendo assim, todos os participantes deverão
                cumprir as nossas diretrizes e protocolos COVID-19, que podem
                mudar a depender das circunstâncias. Por favor, confira a nossa
                página com as{' '}
                <a
                  href="https://www.vidcon.com/sao-paulo/pt/covid-health-safety-guidelines/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Diretrizes de Saúde e Segurança COVID-19
                </a>{' '}
                e a nossa
                <a
                  href="https://www.vidcon.com/sao-paulo/pt/covid-19-policy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Política COVID-19
                </a>
                . Continuaremos atualizando nossas diretrizes e políticas
                dependendo das circunstâncias.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Qual é a política de reembolso e cancelamento dos ingressos/evento?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  <strong className="text-dark">
                    Observe que apenas o comprador/titular do pedido pode
                    solicitar o reembolso ou o cancelamento do ingresso.
                  </strong>
                </p>
                <p>
                  <strong className="text-dark">Política de Reembolso</strong>
                </p>
                <p>
                  Para solicitar o cancelamento de um pedido, entre em contato
                  com atendimento@godream.com.br.
                </p>
                <p>
                  Fique ligado: o reembolso dos ingressos só será aceito se
                  solicitado em até 7 dias corridos após a efetivação do pedido
                  e desde que seja a menos de 48 horas do início do evento.
                </p>
                <p>
                  Se o pedido foi pago com cartão de crédito o estorno será
                  realizado no mesmo cartão utilizado sendo creditado em sua
                  próxima fatura ou subsequente.
                </p>
                <p>
                  Caso o pedido tenha sido pago com boleto bancário o estorno
                  será realizado em conta bancária do responsável pelo pedido em
                  até 15 dias úteis após informada. A solicitação do estorno
                  deve ser realizada por escrito através do e-mail
                  atendimento@godream.com.br informando o número do pedido,
                  nome, CPF e e-mail do responsável utilizado para realizar o
                  pedido.
                </p>
                <p>
                  <strong className="text-dark">
                    Importante: APENAS o titular do pedido pode solicitar um
                    reembolso ou cancelar o(s) ingresso(s)..
                  </strong>
                </p>
                <p>
                  Caso o pedido seja cancelado o valor da taxa de conveniência
                  não será devolvido.
                </p>
                <p>
                  Os ingressos já adquiridos não poderão ser transferidos para
                  outra data do evento.
                </p>
                <p>
                  O cancelamento do ingresso também cancela automaticamente a
                  sua sessão de Meet & Greet.
                </p>
                <p>
                  <strong className="text-dark">
                    O reembolso só pode ser feito para o cartão original usado
                    na compra. Se as informações do seu cartão foram alteradas,
                    entre em contato com seu banco antes de solicitar o
                    reembolso.
                  </strong>
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
    ],
  },
  {
    title: 'Mais Informação',
    question: [
      {
        title:
          'Onde posso encontrar mais informações sobre a VidCon São Paulo?',
        data: (col1) => (
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Você pode acompanhar tudo sobre o VidCon inscrevendo-se em nossa
                lista de e-mails (no final de nosso{' '}
                <a
                  href="https://www.vidcon.com/sao-paulo/pt/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  site
                </a>
                ) e nos seguindo nas redes sociais em todas as plataformas
                @vidconsaopaulo.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Terá uma praça de alimentação?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Sim, a VidCon São Paulo 2023 terá praça de alimentação e
                aceitará cartões de débito, crédito e dinheiro como forma de
                pagamento.
              </div>
            </div>
          </Collapse>
        ),
      },
    ],
  },
  {
    title: 'Acessibilidade',
    question: [
      {
        title: 'Animais de serviço',
        data: (col1) => (
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Animais-guia e de serviço (conforme definido nas leis
                  estaduais e municipais) são permitidos no São Paulo Expo em
                  alinhamento com a Lei Federal nº 11.126/2005 regulamentada
                  pelo Decreto nº 5.904/2006.
                </p>
                <p>
                  <strong className="text-dark">
                    Importante: por favor, não acaricie ou distraia um animal de
                    serviço sem a permissão de seu dono. Esses animais estão
                    trabalhando e não devem ser tocados sem permissão explícita.
                  </strong>
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Diversos',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Não encontrou a resposta para sua dúvida quanto a acessibilidade
                na VidCon São Paulo? Por favor, sinta-se livre para entrar em
                contato conosco pelo saopauloinfo@vidcon.com. Observe que também
                continuaremos a atualizar esta seção das Perguntas frequentes
                com informações adicionais de acessibilidade conforme
                confirmadas.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Cadeira de rodas/Scooters de mobilidade',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Não haverá cadeiras de rodas e scooters disponíveis para
                aluguel. As cadeiras de rodas disponíveis no local serão
                utilizadas apenas em caso de emergência.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Assentos para participantes PCD',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Teremos assentos marcados para participantes PCDs em todas as
                sessões da nossa programação.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Credenciamento acessível',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Teremos uma mesa acessível e dedicada na área de Credenciamento
                aos participantes PCDs. Confira no mapa do evento disponível no
                nosso aplicativo a localização dessa mesa.
              </div>
            </div>
          </Collapse>
        ),
      },
    ],
  },
  {
    title: 'Sua Conta na Go Dream',
    question: [
      {
        title:
          'Onde posso encontrar os meus ingressos na minha conta Go Dream?',
        data: (col1) => (
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Você pode acessar o seu pedido no site da Go Dream com as
                  credenciais do titular da compra e fazer o reenvio do e-mail
                  de confirmação com seu(s) ingresso(s). Basta fazer login na
                  conta do comprador{' '}
                  <a
                    href="https://godream.com.br/account/orders"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    aqui.
                  </a>
                </p>
                <p>
                  Por favor, note que apenas os ingressos ativos poderão ser
                  reenviados para o e-mail do comprador - os ingressos
                  cancelados não. Esta página também mostrará informações sobre
                  o valor total do seu pedido, número de ingressos e assim por
                  diante.
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Quanto tempo um pedido fica preso no carrinho de compra?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Caso tenha adicionado ingressos ao seu carrinho de compra, mas
                não tenha seguido para finalização do pedido, os ingressos
                ficarão disponíveis no carrinho por até 30 minutos.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Preciso trocar o e-mail na minha conta no site da Go Dream. O que eu faço?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Você pode alterar a senha da sua conta Go Dream seguindo as
                  etapas abaixo:
                </p>
                <p style={{ display: 'flex', flexDirection: 'column' }}>
                  <a>
                    1. Acesse{' '}
                    <a
                      href="https://godream.com.br/"
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      https://godream.com.br/
                    </a>
                  </a>
                  <a>2. Faça login na sua conta</a>
                  <a>3. Clique em “Minha conta”</a>
                  <a>4. Clique em “Alterar e-mail”</a>
                  <a>
                    5. Atualize ou altere seu e-mail nos campos correspondentes
                  </a>
                  <a>
                    6. Clique em “Alterar e-mail” na parte inferior da página
                  </a>
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Esqueci minha senha de acesso à minha conta na Go Dream. Como posso recuperá-la?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                <p>
                  Para redefinir sua senha de acesso a sua conta Go Dream siga
                  os passos abaixo:
                </p>
                <p style={{ display: 'flex', flexDirection: 'column' }}>
                  <a>
                    1. Acesse{' '}
                    <a
                      href="https://godream.com.br/"
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      https://godream.com.br/
                    </a>
                  </a>
                  <a>2. Clique em LOGIN;</a>
                  <a>3. Clique em ESQUECEU SUA SENHA;</a>
                  <a>4. Informe o e-mail cadastrado;</a>
                  <a>
                    5. Siga as etapas indicadas no e-mail que você recebeu para
                    redefinir sua senha;
                  </a>
                  <a>
                    6. Siga os passos indicados no e-mail que você recebeu para
                    recuperação da sua senha
                  </a>
                </p>
                <p>
                  Certifique-se de que o e-mail com os próximos passos para
                  redefinição da sua senha não foi para o seu lixo
                  eletrônico/SPAM, pois seu provedor de e-mail pode bloquear o
                  link. Siga as orientações do seu provedor para remover o
                  e-mail de redefinição de senha do lixo e desbloquear os links.
                </p>
                <p>
                  Caso receba a mensagem “e-mail inválido”, envie-nos seu CPF e
                  nome completo para atendimento@godream.com.br, pois pode ter
                  acontecido um erro de digitação na hora do cadastro para que
                  possamos verificar e te ajudar com os próximos passos.
                </p>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Preciso alterar uma informação no meu cadastro. Como fazer?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div
                className="text-muted"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <a>
                  1. Acesse{' '}
                  <a
                    href="https://godream.com.br/"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    https://godream.com.br/
                  </a>
                </a>
                <a>2. Faça o seu login;</a>
                <a>3. Clique em "minha conta";</a>
                <a>
                  4. Você terá a opção de alterar: "meus dados", "alterar senha"
                  e "alterar e-mail";
                </a>
                <a>5. Faça as alterações necessárias;</a>
                <a>6. Clique em "salvar mudanças".</a>
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Preciso de ajuda para concluir a compra do meu ingresso e tenho dúvidas sobre o fluxo de compra.',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Você pode enviar um e-mail para atendimento@godream.com.br com
                um print da sua tela e a equipe da Go Dream vai te atender o
                mais rápido possível.
              </div>
            </div>
          </Collapse>
        ),
      },
    ],
  },
  {
    title: 'Participe da VidCon São Paulo',
    question: [
      {
        title:
          'Quero participar como patrocinador ou expositor na VidCon, com quem eu falo?',
        data: (col1) => (
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Se você tem interesse em ser um patrocinador ou expositor na
                VidCon São Paulo por favor entre em contato{' '}
                <a
                  href="https://www.vidcon.com/sao-paulo/pt/sponsorship-inquiry/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  aqui
                </a>
                .
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title: 'Quero cobrir a VidCon, com quem eu falo?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Se você estiver interessado em se credenciar como imprensa,
                entre em contato com saopauloinfo@vidcon.com.
              </div>
            </div>
          </Collapse>
        ),
      },
      {
        title:
          'Sou uma empresa e gostaria de fazer uma parceria com a VidCon. Com quem posso falar?',
        data: (col) => (
          <Collapse isOpen={col} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Você pode contar pra gente um pouco mais sobre você e como você
                gostaria de fazer parte da nossa programação mandando um e-mail
                para saopauloinfo@vidcon.com. Vamos avaliar o seu caso e, se for
                possível a sua participação no evento como criador, entraremos
                em contato.
              </div>
            </div>
          </Collapse>
        ),
      },
    ],
  },
];
