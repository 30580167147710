import React, { useState } from "react";
// componentes
import BottomMenu from "./bottomMenu";
import MenuSponsors from "./menuSponsors";
import MenuExhibitors from "./menuExhibitors";
import MenuPartners from "./menuPartners";

export default function Sponsors() {
  const [step, setStep] = useState("a");
  return (
    <section style={{ paddingBottom: 100 }}>
      {step === "a" && <MenuSponsors />}
      {step === "b" && <MenuExhibitors />}
      {step === "c" && <MenuPartners />}
      <BottomMenu setStep={setStep} step={step} />
    </section>
  );
}
