import React, { useState, useEffect } from "react";
// componente
import OptionLecture from "./optionLecture";

export default function Schedule07(props) {
  const [mySchedule, setMySchedule] = useState(
    localStorage.getItem("schedule07")
      ? JSON.parse(localStorage.getItem("schedule07"))
      : []
  );

  useEffect(() => {
    localStorage.setItem("schedule07", JSON.stringify(mySchedule));
  }, [mySchedule]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '5%' }}>
      {props.scheduleList &&
        props.scheduleList
          .filter(
            (e) =>
              e.start_time.substr(0, 10).split('-').reverse().join('-') ===
                '07-07-2023' && e.published_room.name === props.room
          )
          .sort((a, b) => Date.parse(a.start_time) - Date.parse(b.start_time))
          .map((e, i) => (
            <OptionLecture
              e={e}
              mySchedule={mySchedule}
              setMySchedule={setMySchedule}
              key={i}
            />
          ))}
    </div>
  );
}
