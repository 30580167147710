import React from "react";
// imgs
import MapImage from "../../assets/images/vidCon/map.png";

export default function ModalMap(props) {
  const { isOpen, toggle, data } = props;

  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? "0%" : "100%",
      }}
    >
      <i className="mdi mdi-chevron-left close-menu" onClick={toggle} />
      <div className="center-img">
        <img src={MapImage} style={{ width: "100%" }} />
      </div>
    </div>
  );
}
