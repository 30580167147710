import comedycentral from '../../../assets/images/vidCon/Logos_Comedy_Central.jpg';
import cely from '../../../assets/images/vidCon/Logos_Cely.jpg';
import mtv from '../../../assets/images/vidCon/Logos_MTv.jpg';
import nickelodeon from '../../../assets/images/vidCon/Logos_Nickelodeon.jpg';
import nonstop from '../../../assets/images/vidCon/Logos_Non_Stop.jpg';
import nude from '../../../assets/images/vidCon/Logos_Nude.jpg';
import paramountpictures from '../../../assets/images/vidCon/Logos_Paramount_Pictures.jpg';
import paramount from '../../../assets/images/vidCon/Logos_Paramount+.jpg';
import stages from '../../../assets/images/vidCon/Logos_Stages.jpg';
import hotmart from '../../../assets/images/vidCon/Logos_hotmart.jpg';
import sunny from '../../../assets/images/vidCon/Logos_sunny.jpg';
import winning from '../../../assets/images/vidCon/Logos_winning.jpg';

export const exhibitorsData = [
  {
    name: 'COMEDY CENTRAL',
    img: comedycentral,
    description:
      'Do que riem os criadores e fãs da internet brasileira? Um pocket stage da nova série "Do Que Riem?" do Comedy Central te espera com presenças surpresas e especiais. Maurício Meirelles, Babu Carreira e Jhordan Matheus se revezam no palco do programa e também no espaço da Vidcon!',
    facebook: 'https://www.facebook.com/comedycentralbr/',
    instagram: 'https://www.instagram.com/comedycentralbr/',
    twitter: 'https://twitter.com/comedycentralbr',
    tiktok: 'https://www.tiktok.com/@comedycentralbr?lang=en',
  },
  {
    name: 'CELY',
    img: cely,
    description:
      '<p>Já pensou em fazer uma selfie incrível, participar de uma campanha de influência em tempo real e ainda concorrer a prêmios?<p> <p>A cely, martech de influência responsável pela curadoria de temas e talentos da VidCon, preparou um stand para dar luz e brilho à essência de todes.</p> <p>Um espaço iluminado onde você pode expressar autenticidade e criatividade de conteúdo para influenciar e se divertir com fotos tiradas por um fotógrafo.</p> <p>Deixe o seu brilho no máximo e venha viver a experiência de produzir a sua própria “publi”.</p>',
    facebook: 'https://www.facebook.com/sejacely',
    instagram: 'https://www.instagram.com/cely.br/',
    twitter: 'https://twitter.com/cely_br',
    tiktok: '',
  },

  {
    name: 'HOTMART',
    img: hotmart,
    description:
      'Ativação do Hotmart Camp Creators, curso online, inédito e 100% gratuito com aulas co criadas com creators de renome como Hyeser, Monique Evelle, Letícia Imai e Larissa Rodrigues, do Hábitos que Mudam, entre outros',
    facebook: 'https://www.facebook.com/hotmartofficial/',
    instagram: 'https://www.instagram.com/hotmart/?hl=en',
    twitter: 'https://twitter.com/hotmart?lang=en',
    tiktok: 'https://www.tiktok.com/@hotmart?lang=en',
  },
  {
    name: 'MTV',
    img: mtv,
    description:
      'O estúdio MTV receberá convidados e creators para entrevistas descontraídas, que poderão ser acompanhadas pela galera que está na VidCon São Paulo 2023. Nos vemos na Área de Exposição!',
    facebook: 'https://www.facebook.com/MTVBrasil',
    instagram: 'https://www.instagram.com/mtvbrasil',
    twitter: 'https://www.twitter.com/mtvbrasil',
    tiktok: 'https://www.tiktok.com/@mtvbrasil?lang=en',
  },
  {
    name: 'NICKELODEON',
    img: nickelodeon,
    description:
      'O lançamento do novo filme Tartarugas Ninjas Caos Mutante está chegando aos cinemas! Para entrar no clima de Nova Iorque e ativar as habilidades ninjas que existem dentro de você o espaço de Paramount Pictures junto com a Nickelodeon te desafiará em um mini parkour valendo brindes da franquia, mas cuidado com as estrelas ninjas... Você ainda poderá tirar aquela foto com as tartarugas em sessões de meet & greet ao longo do evento e fazer uma foto em um photo opportunity exclusivo.',
    facebook: 'https://m.facebook.com/nickelodeonBR/?locale=pt_BR',
    instagram: 'https://www.instagram.com/nickelodeonbr/?hl=en',
    twitter: 'https://twitter.com/NickelodeonBR',
    tiktok: '',
  },
  {
    name: 'NONSTOP',
    img: nonstop,
    description:
      '<p>VISITE NOSSO STAND PARA PARTICIPAR DA SELEÇÃO DA NOSSA NOVA ESTRELA!</p><p>Sua oportunidade de apresentar o que você tem de melhor pra Nonzinha é AGORA! Todos os vídeos gravados no stand serão avaliados, e o dono do melhor vídeo será nosso novo agenciado!</p>',
    facebook: '',
    instagram: 'https://www.instagram.com/nonstopproducoes/?hl=en',
    twitter: '',
    tiktok: 'https://www.tiktok.com/@nonstopproducoes',
  },
  {
    name: 'NUDE',
    img: nude,
    description:
      '<p>Hey, estacionamos nosso tuk-tuk na VidCon e para uma marca de ~leite de aveia~ dessa vez estamos até que mais ousados (u-hum), trocamos selfie por Nude (epa) mas acredite, esse é o único Nude possível.</p><p>Feito de aveia 🌱para usar no lugar daquele outro ~leite~, sem ingredientes impróprios, com baixa pegada de carbono & B Corp, espie você mesmo e tire suas próprias conclusões.</p>',
    facebook: '',
    instagram: 'https://www.instagram.com/heynude_/',
    twitter: '',
    tiktok: '',
  },
  {
    name: 'PARAMOUNT PICTURES',
    img: paramountpictures,
    description:
      'O lançamento do novo filme Tartarugas Ninja: Caos Mutante está chegando aos cinemas em agosto! Para entrar no clima de Nova Iorque e ativar as habilidades ninja que existem dentro de você, o espaço de Paramount Pictures te desafiará em um mini parkour valendo brindes da franquia, mas cuidado com as estrelas ninja... Você ainda poderá tirar aquela foto com as tartarugas em sessões de meet & greet ao longo do evento e fazer uma foto em um photo opportunity exclusivo. E depois disso, dia 31 de agosto, corre para a estreia de Tartarugas Ninja: Caos Mutante, exclusivamente em todos os cinemas do Brasil.',
    facebook: 'https://www.facebook.com/paramountbrasil?_rdc=1&_rdr',
    instagram: 'https://www.instagram.com/paramountbrasil/',
    twitter: 'https://twitter.com/ParamountBrasil',
    tiktok: 'https://www.tiktok.com/@paramountbrasil?lang=en',
  },
  {
    name: 'PARAMOUNT+',
    img: paramount,
    description:
      'Explore nossa montanha de entretenimento em uma experiência super divertida! Tobogã gigante, dois níveis de escalada, cabines de ar, meet & greet com talentos e muitos brindes! O espaço Paramount+ te espera com o melhor do conteúdo das marcas Paramount e muita diversão. Te vejo na Área de Exposição da VidCon São Paulo.',
    facebook: 'https://www.facebook.com/ParamountPlusBr/',
    instagram: 'https://www.instagram.com/paramountplusbr/?hl=en',
    twitter: 'https://twitter.com/paramountplusbr',
    tiktok: 'https://www.tiktok.com/@paramountplusbr',
  },
  {
    name: 'STAGES',
    img: stages,
    description:
      '<p>A Stages é uma ferramenta para criadores profissionais, infoprodutores e professores se conectarem com seu público e impulsionar seus negócios de conteúdo em vídeo, com uma solução única de monetização.</p> <p>Junte-se a nós e aos nossos convidados Titi Muller, Rafa Lotto e Gu Alonge em nosso painel “A Nova Era das Plataformas”, dia 7 de julho às 16h20, na Sala 204.</p><p>Participe do nosso painel na VidCon e concorra a uma câmera GoPro! Para concorrer a uma das 3 câmeras GoPro é muito fácil: basta assistir ao painel Stages, “A Nova Era das Plataformas”, no sábado.</p><p>Saiba mais, visite getstages.io ou baixe o aplicativo e teste gratuitamente agora!</p><p>DIRECT YOUR STORY!</p>',
    facebook: '',
    instagram: 'https://www.instagram.com/getstages/',
    twitter: '',
    tiktok: '',
  },
  {
    name: 'SUNNY',
    img: sunny,
    description:
      '<p>Durante a VIDCON a Sunny Brinquedos fará o lançamento oficial do Bitzee, o novo pet virtual interativo que promete ser um sucesso entre as crianças e os adultos nostálgicos que eram grandes fãs de bichinhos virtuais.</p> <p>Dentro do estande os visitantes poderão conhecer essa novidade em primeira mão e interagir com o brinquedo!</p><p>E para os fãs da pelúcia mais fofa e famosa do mundo: a Sunny também terá um espaço dedicado aos lançamentos de SQUISHMALLOWS e o público poderá aproveitar um espaço instagramável -e fofo- da marca!</p>',
    facebook: 'https://www.facebook.com/sunnybrinquedos/',
    instagram: 'https://www.instagram.com/sunnybrinquedos/',
    twitter: '',
    tiktok: 'https://www.tiktok.com/@sunnybrinquedos',
  },
  {
    name: 'WINNIN',
    img: winning,
    description:
      '<p>Desvende insights valiosos sobre o comportamento do consumidor através do que ele assiste, identifique oportunidades de mercado e construa conexões autênticas com seu público-alvo para impulsionar a relevância da sua marca.</p>',
    facebook: '',
    instagram: 'https://www.instagram.com/winnin/',
    twitter: '',
    tiktok: 'https://www.tiktok.com/@winnin_insights',
  },
];

