import React, { useState, useEffect } from "react";

import ModalInfoLecture from "./modalInfoLecture";

export default function OptionLecture(props) {
  const { e, mySchedule } = props;
  const [modal, setModal] = useState(false);
  const [selected, setselected] = useState(false);

  function favoriteSchedule() {
    const scheduleSave = mySchedule.filter((f) => f.title === e.title);

    if (scheduleSave.length > 0) {
      var index = mySchedule.findIndex((f) => f.title === e.title);
      var newStateSchedule = mySchedule.filter((f, i) => i != index);
      props.setMySchedule(newStateSchedule);
    } else {
      props.setMySchedule((state) => {
        return [
          ...state,
          {
            title: e.title,
            id: e.id,
          },
        ];
      });
    }
  }

  useEffect(() => {
    var schedule = mySchedule.filter((f) => f.title === e.title);
    if (schedule.length > 0) {
      setselected(true);
    } else {
      setselected(false);
    }
  }, [mySchedule]);

  function formateHours(e) {
    const date = new Date(e);
    const hours =
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return hours + ":" + minutes;
  }
  return (
    <div className="option-schedule">
      <ModalInfoLecture
        data={e}
        isOpen={modal}
        toggle={() => setModal(false)}
      />
      <div
        style={{ width: "75%", display: "flex", alignItems: "center" }}
        // onClick={() => console.log(e)}
        onClick={() => setModal(true)}
      >

        <div className="data-option-schedule" style={{ paddingLeft: 10 }}>
          {e.start_time ? (
            <a style={{ color: "blue", fontWeight: "bolder" }}>
              {formateHours(e.start_time)} - {formateHours(e.end_time)}
            </a>
          ) : null}

          <a style={{ fontWeight: "bold" }}>{e.title}</a>
          <a>
            <i className="mdi mdi-map-marker" />
            {e.published_room.name}
          </a>
        </div>
      </div>

      <div style={{ width: "25%", display: "flex", justifyContent: "center" }}>
        <div onClick={() => favoriteSchedule()}>
          {selected ? (
            <i
              className="mdi mdi-heart add-option-schedule"
              style={{ color: "red" }}
            />
          ) : (
            <i className="mdi mdi-heart-outline add-option-schedule" />
          )}
        </div>
      </div>
    </div>
  );
}
