import React, { useEffect, useState } from "react";
// componentes
import Schedule07 from "./schedule07";
import Schedule08 from "./schedule08";
import Schedule09 from "./schedule09";

export default function ModalInfo(props) {
  const { isOpen, toggle, data } = props;
  const [scheduleList, setScheduleList] = useState([]);
  const [stepDate, setStepDate] = useState("a");
  useEffect(() => {
    if (data && scheduleList.length === 0) {
      var dataList = [];
      data.published_programme_item_ids.map((e) => {
        props.scheduleList.map((schedule) => {
          if (e === schedule.id) {
            dataList.push(schedule);
          }
        });
      });
      const arrayFormated = dataList.filter((e, i, self) => {
        return self.indexOf(e) === i;
      });
      setScheduleList(arrayFormated);
    }
  }, [data]);

  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? "0%" : "100%",
      }}
    >
      {data ? (
        <>
          <i className="mdi mdi-chevron-left close-menu" onClick={toggle} />

          <div className="limit" style={{ paddingTop: 70 }}>
            <div className="div-title">{data.name}</div>
            <div className="row-options">
              <a
                className={`option-row-options ${
                  stepDate === "a" && "option-row-options-selected"
                }`}
                onClick={() => setStepDate("a")}
              >
                07 de Julho
              </a>
              <a
                className={`option-row-options ${
                  stepDate === "b" && "option-row-options-selected"
                }`}
                onClick={() => setStepDate("b")}
              >
                08 de Julho
              </a>
              {data.name !== 'Sala 206' && <a
                className={`option-row-options ${
                  stepDate === "c" && "option-row-options-selected"
                }`}
                onClick={() => setStepDate("c")}
              >
                09 de Julho
              </a>}
            </div>

            {stepDate === "a" && <Schedule07 scheduleList={scheduleList} room={data?.name}/>}
            {stepDate === "b" && <Schedule08 scheduleList={scheduleList} room={data?.name}/>}
            {stepDate === "c" && <Schedule09 scheduleList={scheduleList} room={data?.name} />}
          </div>
        </>
      ) : null}
    </div>
  );
}
