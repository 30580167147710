export const sponsors = [
  {
    name: 'SANTANDER',
    url: 'https://www.santander.com.br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/santanderbrasil',
      instagram: 'https://www.instagram.com/santanderbrasil/',
      twitter: 'https://twitter.com/santander_br',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-Santander.jpg'),
  },
  {
    name: 'LA ROCHE POSAY',
    url: 'https://www.laroche-posay.pt/',
    socialMedia: {
      facebook: '',
      instagram: 'https://www.instagram.com/larocheposaybr/',
      twitter: '',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-larorocheposay.png'),
  },
  {
    name: 'Tinder',
    url: 'https://tinder.com/pt',
    socialMedia: {
      facebook: '',
      instagram: 'https://www.instagram.com/tinderbrasil/',
      twitter: 'https://twitter.com/tinderbrasil',
      tiktok: 'https://www.tiktok.com/@tinder.brasil',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-Tinder.jpg'),
  },
  ,
  {
    name: 'Hotmart',
    url: 'https://www.hotmart.com/',
    socialMedia: {
      facebook: 'https://www.facebook.com/hotmartofficial/',
      instagram: 'https://www.instagram.com/hotmart/?hl=en',
      twitter: 'https://twitter.com/hotmart?lang=en',
      tiktok: 'https://www.tiktok.com/@hotmart?lang=en',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-Hotmart.jpg'),
  },
  {
    name: 'Magalu',
    url: 'https://www.magazineluiza.com.br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/magazineluiza/',
      instagram: 'https://www.instagram.com/magazineluiza/',
      twitter: 'https://twitter.com/magalu?lang=en',
      tiktok: 'https://www.tiktok.com/@magalu?lang=en',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-Magalu.jpg'),
  },

  {
    name: 'SUNNY',
    url: 'https://www.sunnybrinquedos.com.br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/sunnybrinquedos/',
      instagram: 'https://www.instagram.com/sunnybrinquedos/',
      twitter: '',
      tiktok: 'https://www.tiktok.com/@sunnybrinquedos',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-sunny.jpg'),
  },
  {
    name: 'TikTok',
    url: 'https://www.tiktok.com/',
    socialMedia: {
      facebook: '',
      instagram: '',
      twitter: '',
      tiktok: 'https://www.tiktok.com/',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-TikTok.jpg'),
  },
  {
    name: 'AirMedia',
    url: 'https://air.io/en',
    socialMedia: {
      facebook: 'https://www.facebook.com/AIRMediaTechCEE',
      instagram: 'https://www.instagram.com/air_media_tech_cee/',
      twitter: '',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-airmedia.jpeg'),
  },
  {
    name: 'Brwnocc',
    url: 'https://brwno.cc/ ',
    socialMedia: {
      facebook: '',
      instagram: 'https://www.instagram.com/bruno.wx/',
      twitter: '',
      tiktok: 'https://www.tiktok.com/@bruno.wx',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-brwno-cc.jpg'),
  },
  {
    name: 'COMEDY CENTRAL',
    url: 'https://www.comedycentral.com.br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/comedycentralbr/',
      instagram: 'https://www.instagram.com/comedycentralbr/',
      twitter: 'https://twitter.com/comedycentralbr',
      tiktok: 'https://www.tiktok.com/@comedycentralbr?lang=en',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-ComedyCentral.jpg'),
  },
  {
    name: 'METDAAN',
    url: 'https://metdaan.media/',
    socialMedia: {
      facebook: 'https://www.facebook.com/MetDaanMagazine',
      instagram: 'https://www.instagram.com/metdaan',
      twitter: '',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-metadan.jpg'),
  },
  {
    name: 'MTV',
    url: 'https://www.mtv.com.br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/MTVBrasil',
      instagram: 'https://www.instagram.com/mtvbrasil',
      twitter: 'https://www.twitter.com/mtvbrasil',
      tiktok: 'https://www.tiktok.com/@mtvbrasil?lang=en',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-MTv.jpg'),
  },
  {
    name: 'NICKELODEON',
    url: 'https://www.nickelodeon.com.br/',
    socialMedia: {
      facebook: 'https://m.facebook.com/nickelodeonBR/?locale=pt_BR',
      instagram: 'https://www.instagram.com/nickelodeonbr/?hl=en',
      twitter: 'https://twitter.com/NickelodeonBR',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-Nickelodeon.jpg'),
  },
  {
    name: 'NONSTOP',
    url: 'https://nonstopproducoes.com.br/',
    socialMedia: {
      facebook: '',
      instagram: 'https://www.instagram.com/nonstopproducoes/?hl=en',
      twitter: '',
      tiktok: 'https://www.tiktok.com/@nonstopproducoes',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-Non_Stop.jpg'),
  },
  {
    name: 'NUDE',
    url: 'https://heynude.com.br/',
    socialMedia: {
      facebook: '',
      instagram: 'https://www.instagram.com/heynude_/',
      twitter: '',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Nude.jpg'),
  },
  {
    name: 'PARAMOUNT PICTURES',
    url: 'http://www.paramountpictures.com.br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/paramountbrasil?_rdc=1&_rdr',
      instagram: 'https://www.instagram.com/paramountbrasil/',
      twitter: 'https://twitter.com/ParamountBrasil',
      tiktok: 'https://www.tiktok.com/@paramountbrasil?lang=en',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-ParamountPictures.jpg'),
  },
  {
    name: 'PARAMOUNT+',
    url: 'https://www.paramountplus.com/br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/ParamountPlusBr/',
      instagram: 'https://www.instagram.com/paramountplusbr/?hl=en',
      twitter: 'https://twitter.com/paramountplusbr',
      tiktok: 'https://www.tiktok.com/@paramountplusbr',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-Paramount+.jpg'),
  },
  {
    name: 'PLUTO TV',
    url: 'https://pluto.tv/pt/live-tv/csi-miami-ptv4?utm_source=Google&utm_medium=PaidSearch&utm_campaign=1002009&utm_content=10008104&gclid=Cj0KCQjw1_SkBhDwARIsANbGpFsbqbTYdQXzVPzU86hvSVKuBNeXHP3UCcJdhOZcdahJRl_Z3RdCJpsaAiINEALw_wcB',
    socialMedia: {
      facebook: 'https://www.facebook.com/plutotvbr/?locale=pt_BR',
      instagram: 'https://www.instagram.com/plutotvbr/?hl=en',
      twitter: 'https://twitter.com/PlutoTVBR?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
      tiktok: 'https://www.tiktok.com/@plutotvbr?lang=en',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-Pluto_TV.jpg'),
  },
  {
    name: 'STAGES',
    url: 'https://www.getstages.io/?utm_source=vidcon_site&utm_medium=logo&utm_campaign=growth&utm_content=vidcon',
    socialMedia: {
      facebook: '',
      instagram: 'https://www.instagram.com/getstages/',
      twitter: '',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-stages.jpg'),
  },
  {
    name: 'WINNIN',
    url: 'https://winnin.com/',
    socialMedia: {
      facebook: '',
      instagram: 'https://www.instagram.com/winnin/',
      twitter: '',
      tiktok: 'https://www.tiktok.com/@winnin_insights',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-winnin.jpg'),
  },
  {
    name: 'ComfortNovaPaulista',
    url: 'https://www.reserveatlantica.com.br/hotel/comfort-nova-paulista',
    socialMedia: {
      facebook: 'https://www.facebook.com/comfortnovapaulista',
      instagram: 'https://www.instagram.com/comfortnovapaulista/?hl=en',
      twitter: '',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-Paulista.jpg'),
  },

  {
    name: 'RadissonBlu',
    url: 'https://www.radissonhotelsamericas.com/pt-br/hotels/radisson-blu-sao-paulo',
    socialMedia: {
      facebook: 'https://www.facebook.com/radissonblusaopaulo',
      instagram: 'https://www.instagram.com/radissonblusaopaulo/?hl=en',
      twitter: '',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-radissonblu.jpg'),
  },
  {
    name: 'Globo',
    url: 'https://www.globo.com/',
    socialMedia: {
      facebook: 'https://www.facebook.com/globo/',
      instagram: 'https://www.instagram.com/somosglobo/?hl=en',
      twitter: 'https://twitter.com/globo?lang=en',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-globo.jpg'),
  },
  {
    name: 'INSTAGRAM',
    url: 'https://about.instagram.com/pt-br',
    socialMedia: {
      facebook: '',
      instagram: 'https://www.instagram.com/instagram/',
      twitter: '',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-Instagram.jpg'),
  },
  {
    name: 'MeioMensagem',
    url: 'https://www.meioemensagem.com.br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/meioemensagem',
      instagram: 'https://www.instagram.com/meioemensagem/',
      twitter: 'https://twitter.com/meioemensagem',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-meio-mensagem.jpg'),
  },
  {
    name: 'NWB',
    url: 'https://nwb.com.br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/NWB.NetworkBrasil/',
      instagram: 'https://www.instagram.com/nwb.oficial/',
      twitter: '',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-NWB 1080x300.jpg'),
  },
  {
    name: 'AsrMidia',
    url: 'https://asrmidia.com.br/?gclid=CjwKCAjw67ajBhAVEiwA2g_jECfkTFs9zENjifupjLelx-6FFn-NWr6m1IeiALzo-vZmx7b6UduaLBoCcM4QAvD_BwE',
    socialMedia: {
      facebook: 'https://www.facebook.com/asrmidiaexterior/?locale=pt_BR',
      instagram: 'https://www.instagram.com/asrmidia/?hl=en',
      twitter: ' https://twitter.com/midiaexterna?lang=en',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-ASR.jpg'),
  },

  {
    name: 'BloombergLinea',
    url: 'https://bloomberglinea.br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/BloombergLineaBrasil',
      instagram: 'https://www.instagram.com/bloomberglineabrasil/',
      twitter: 'https://twitter.com/BloombergLineaB',
      tiktok: 'https://www.tiktok.com/@bloomberglineabrasil',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-bloomberg.jpg'),
  },
  // {
  //   name: 'Cinemark',
  //   url: 'https://www.cinemark.com.br/',
  //   socialMedia: {
  //     facebook: 'https://www.facebook.com/cinemarkoficial',
  //     instagram: 'https://www.instagram.com/cinemarkoficial/',
  //     twitter: 'https://twitter.com/cinemarkoficial',
  //     tiktok: '',
  //   },
  //   logo: require('../assets/images/vidCon/Logo-Texto-cinemark.jpg'),
  // },

  {
    name: 'FolhaSp',
    url: 'https://www.folha.uol.com.br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/folhadesp/',
      instagram: 'https://www.instagram.com/folhadespaulo/',
      twitter: 'https://twitter.com/folha',
      tiktok: 'https://www.tiktok.com/@folha',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-folhasp.jpg'),
  },
  {
    name: 'RadioMix',
    url: 'https://radiomixfm.com.br/',
    socialMedia: {
      facebook: 'https://www.facebook.com/radiomixfm/',
      instagram: 'https://www.instagram.com/radiomixfm/',
      twitter: 'https://twitter.com/radiomixfm/',
      tiktok: 'https://www.tiktok.com/@radiomixfm?lang=en',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-radiomix.jpg'),
  },
  {
    name: 'Uol',
    url: 'https://uol.com.br?utm_source=site&utm_medium=cpc&utm_campaign=traf&utm_id=uolvidcon',
    socialMedia: {
      facebook: 'https://www.facebook.com/UOL',
      instagram: 'https://www.instagram.com/uoloficial/',
      twitter: 'https://twitter.com/UOL',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-uol.jpg'),
  },
  {
    name: 'Cely',
    url: 'https://cely.co/',
    socialMedia: {
      facebook: 'https://www.facebook.com/sejacely',
      instagram: 'https://www.instagram.com/cely.br/',
      twitter: 'https://twitter.com/cely_br',
      tiktok: '',
    },
    logo: require('../assets/images/vidCon/Logo-Texto-cely.jpg'),
  },
];
