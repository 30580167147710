import React from "react";

export default function Meeting() {
  return (
    <section
      className="section-align-center"
      style={{ justifyContent: "center", padding: "0 5%" }}
    >
      <div style={{ textAlign: "center" }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </div>

      <a className="add-schedule" style={{ margin: "20px 0 0 0" }}>Sala de Network</a>
      <a className="add-schedule" style={{ margin: 5 }}>Lounge</a>
    </section>
  );
}
