import React from "react";
// imgs
import MapImage from "../../../assets/images/vidCon/map.png";

export default function MenuFather() {
  return (
    <div>
      <iframe
        id="cross_domain_page"
        key="viewer"
        src="https://www.vidcon.com/sao-paulo/pt/parents-chaperones/"
        style={{
          position: "relative",
          border: "none",
          top: "0!important",
          left: "0!important",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
        }}
      />
    </div>
  );
}
