import React, { useState, useEffect, useRef } from 'react';
// nomes na listagem
import { exhibitorsSchedulle } from '../../../utils/ExhibitorsSchedulle/index';
// componentes
import OptionSpeaker from './optionSpeaker';
// import SkeletonList from '../../../components/skeleton/list';
import { LoadSpinner } from '../../../components/LoadingSpinner';
import * as Sentry from '@sentry/react';

//service
import { getAllSpeakers, getAllItems } from '../../../utils/services/index';
import { Spinner } from 'reactstrap';
export default function MenuLineup() {
  const [search, setSearch] = useState('');
  const [dataSchedule, setDataSchedule] = useState([]);
  const [listSpeakers, setListSpeakers] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);

  // carregando
  const [loading, setLoading] = useState(true);

  const [currentPage, _setCurrentPageRef] = useState(0);
  const [lastPage, _setLastPage] = useState(0);
  const [isLoading, _setIsLoading] = useState(false);

  const currentPageRef = useRef(currentPage);
  const lastPageRef = useRef(lastPage);
  const isLoadingPageRef = useRef(isLoading);
  const priorSearchRef = useRef(false);

  const setCurrentPage = (data) => {
    currentPageRef.current = data;
    _setCurrentPageRef(data);
  };

  const setLastPage = (data) => {
    lastPageRef.current = data;
    _setLastPage(data);
  };

  const setIsLoading = (data) => {
    isLoadingPageRef.current = data;
    _setIsLoading(data);
  };
  const LoadSpinner = () => {
    const size = [0.6, 1, 1.4];
    return (
      <>
        {[...Array(3)].map((e, i) => (
          <Spinner
            key={i}
            style={{
              width: `${size[i]}rem`,
              height: `${size[i]}rem`,
              marginRight: '2px',
            }}
            type="grow"
          />
        ))}
      </>
    );
  };

  function getSpeakers() {
    isLoadingPageRef.current = true;
    let curPage = currentPageRef.current + 1;
    let limit = 50;

    if (search) {
      curPage = 1;
      limit = 1000;
    }
    getAllSpeakers(curPage, search, limit)
      .then((res) => {
        if (res.data.response) {
          if (search) {
            setListSpeakers(res.data.response);
          } else {
            if (priorSearchRef.current) {
              setListSpeakers(res.data.response);
              priorSearchRef.current = false;
            } else {
              setListSpeakers((prev) => [...prev, ...res.data.response]);
            }
            setCurrentPage(curPage);
          }
          setLastPage(res.data.pagination.pages);
          setLoading(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        Sentry.setContext('response', error?.response?.data);
        Sentry.captureMessage('Request Get All Speakers from Lineup');
      });
  }
  async function getItems() {
    await getAllItems()
      .then((res) => {
        if (res.data.response) {
          setScheduleList(res.data.response);
        }
      })
      .catch((error) => {
        Sentry.setContext('response', error?.response?.data);
        Sentry.captureMessage('Request Get Items from Lineup');
      });
  }

  useEffect(() => {
    currentPageRef.current = 0;
    lastPageRef.current = 0;
    isLoadingPageRef.current = false;
    priorSearchRef.current = false;
    window.scrollTo(0, 0);
    getItems();
  }, []);

  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    if (
      !isLoadingPageRef.current &&
      bottom &&
      currentPageRef.current + 1 <= lastPageRef.current
    ) {
      setIsLoading(true);
      getSpeakers();
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: false,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    priorSearchRef.current = true;
    if (search) {
      currentPageRef.current = 0;
    }
    getSpeakers();
  }, [search]);

  useEffect(() => {
    if (listSpeakers.length > 0) {
      let data = listSpeakers
        .filter((e) =>
          e.full_publication_name
            .toLocaleLowerCase()
            .includes(search.toLowerCase())
        )
        .reduce((r, e) => {
          let group = e.full_publication_name[0].toLocaleUpperCase();
          if (!r[group]) r[group] = { group, children: [e] };
          else r[group].children.push(e);
          return r;
        }, {});

      setDataSchedule(Object.values(data));
    }
  }, [listSpeakers]);

  return (
    <div className="list-itens-column">
      <div className="div-padding" style={{ backgroundColor: 'white' }}>
        <input
          placeholder="Pesquise"
          className="search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {loading ? (
        <div style={{ marginTop: 40 }}>
          <div className="spinnerloading-container">
            <LoadSpinner />
            {/* <SkeletonList count={7} /> */}
          </div>
        </div>
      ) : (
        <>
          <div
            className="div-padding"
            style={{ padding: '0 5%', marginTop: 20 }}
          >
            {dataSchedule &&
              dataSchedule
                .sort(function (a, b) {
                  if (a.group < b.group) {
                    return -1;
                  }
                  if (a.group > b.group) {
                    return 1;
                  }
                  return 0;
                })
                .map((e, i) => (
                  <div key={i}>
                    <div style={{ margin: '8px 0', display: 'flex' }}>
                      <a className="title-contact">{e.group}</a>
                    </div>
                    {e.children.map((item, index) => (
                      <OptionSpeaker
                        item={item}
                        i={index}
                        scheduleList={scheduleList}
                      />
                    ))}
                  </div>
                ))}
            <div style={{ textAlign: 'center' }}>
              {isLoading && <LoadSpinner />}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
