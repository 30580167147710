import React from "react";
// img
// import VidCon from "../../assets/images/vidCon/map.png";

export default function ModalInfo(props) {
  const { isOpen, toggle, data } = props;
  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? '0%' : '100%',
      }}
    >
      <i className="mdi mdi-chevron-left close-menu" onClick={toggle} />
      <img src={data.img} className="img-modal" />

      <div className="limit">
        <div className="div-title">{data.name ? <a>{data.name}</a> : null}</div>
        <a dangerouslySetInnerHTML={{ __html: data.description }} />

        <div style={{ marginTop: '16px', textAlign: 'center' }}>
          <i className="mdi mdi-map-marker" size="30px" /> Local: {data.address}
        </div>

        {data?.menu && (
          <div
            style={{
              marginTop: '20px',
              textAlign: 'center',
            }}
          >
            <a
              style={{ color: '#4c4c4c', fontWeight: 'bold' }}
              href={data?.menu}
              target="_blank"
              rel="noreferrer"
            >
              Clique Aqui para Acessar o Cardápio
            </a>
          </div>
        )}
        <div className="div-icons-sponsors" style={{ marginTop: '20px' }}>
          {/* <i className="mdi mdi-facebook" style={{ fontSize: 30 }} /> */}
          {data?.instagram && (
            <a href={data.instagram} target="_blank" rel="noreferrer">
              <i
                className="mdi mdi-instagram"
                style={{ fontSize: 30, margin: '0 20px' }}
              />
            </a>
          )}
          {/* <i className="mdi mdi-twitter" style={{ fontSize: 30 }} /> */}
        </div>
      </div>
    </div>
  );
}
