import React, { useState } from "react";
// img
import Map from "../../../assets/images/vidCon/map.png";
// componente
import Speackers from "./speakers";

export default function ModalInfoActivity(props) {
  const { isOpen, toggle } = props;

  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? "0%" : "100%",
      }}
    >
      <i className="mdi mdi-chevron-left close-menu" onClick={toggle} />

      <i
        className="mdi mdi-share close-menu"
        style={{ right: 0, left: "auto" }}
      />

      {/* <img src={Map} className="img-modal" /> */}

      <div className="limit" style={{ paddingTop: 70 }}>
        <div className="div-title" style={{ fontWeight: "bold", fontSize: 25 }}>
          Lorem Ipsum
        </div>

        <a>
          <i className="mdi mdi-map-marker" />
          localização
        </a>

        <a>
          <i className="mdi mdi-timer" />
          Hora inicio - Hora fim
        </a>

        <div className="div-description-schedule">
          <div className="data-description-schedule">
            <a style={{ fontWeight: "bold", fontSize: 17 }}>description</a>
            <a>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </a>
          </div>
        </div>

        <div>
          <Speackers />
          <Speackers />
          <Speackers />
        </div>
      </div>
    </div>
  );
}
