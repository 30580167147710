import React, { useState, useEffect } from "react";
// componente
import OptionSchedule from "../optionSchedule";

export default function Schedule08(props) {
  const [scheduleList, setScheduleList] = useState([]);
  const [mySchedule, setMySchedule] = useState(
    localStorage.getItem("schedule08")
      ? JSON.parse(localStorage.getItem("schedule08"))
      : []
  );

  useEffect(() => {
    localStorage.setItem("schedule08", JSON.stringify(mySchedule));
  }, [mySchedule]);

  useEffect(() => {
    var dataList = [];
    props.scheduleList.map((e) => {
      e.theme_names.map((theme) => {
        if (theme.name === "Community Track") {
          dataList.push(e);
        }
      });
    });
    const arrayFormated = dataList.filter((e, i, self) => {
      return self.indexOf(e) === i;
    });
    setScheduleList(arrayFormated);
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '5%' }}>
      {/* <a onClick={() => console.log(props.scheduleList)}>aqui</a> */}
      {scheduleList
        .filter(
          (e) =>
            e.start_time.substr(0, 10).split('-').reverse().join('-') ===
            '08-07-2023'
        )
        .filter((e) =>
          e.title.toLocaleLowerCase().includes(props.search.toLowerCase())
        )
        .map((e, i) => (
          <OptionSchedule
            mySchedule={mySchedule}
            setMySchedule={setMySchedule}
            title={e.title}
            data={e}
            key={i}
          />
        ))}
    </div>
  );
}
