import React, { useState } from "react";

export default function TopMenu(props) {
  return (
    <div className="menu-top">
      <a
        className={`option-menu-top ${
          props.step === "a" && "option-menu-top-selected"
        }`}
        onClick={() => props.setStep("a")}
      >
        Quando
      </a>
      <a
        className={`option-menu-top ${
          props.step === "b" && "option-menu-top-selected"
        }`}
        onClick={() => props.setStep("b")}
      >
        Onde
      </a>
      
      <a
        className={`option-menu-top ${
          props.step === "c" && "option-menu-top-selected"
        }`}
        onClick={() => props.setStep("c")}
      >
        Tipo
      </a>
    </div>
  );
}
