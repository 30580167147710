import casadopaodequeijo from '../../assets/images/vidCon/casadopaodequeijo.jpg';
import gendai from '../../assets/images/vidCon/gendai.jpg';
import nomade from '../../assets/images/vidCon/nomade.jpg';
import priasehat from '../../assets/images/vidCon/priasehat.jpg';
import spoleto from '../../assets/images/vidCon/spoleto.jpg';
import tropicalsucos from '../../assets/images/vidCon/tropicalsucos.jpg';
import xpicanha from '../../assets/images/vidCon/xpicanha.jpg';

export const exhibitorsSchedulle = [
  {
    name: 'CASA DO PÃO DE QUEIJO',
    img: casadopaodequeijo,
    address: 'Próximo da Praça de Alimentação 1',
    description:
      'A Casa do Pão de Queijo é uma empresa tipicamente brasileira com quase 50 anos de sucesso.</br> Pioneira na comercialização de pão de queijo, foi responsável pelo hábito que virou mania nacional: comer pão de queijo sempre quentinho. </br>O sucesso deu origem a toda uma linha de produtos, com panettones, doces, cafés e salgados.',
    menu: 'https://drive.google.com/file/d/1H18lpPBkC6WQLpq5jrbpcNwwBxFPsAKC/view?usp=sharing',
    instagram: 'https://www.instagram.com/casadopaodequeijo/',
  },
  {
    name: 'GENDAI',
    img: gendai,
    address: 'Praça de Alimentação 1',
    description:
      'A maior rede de culinária japonesa do mercado brasileiro. <br>O Gendai tem pratos saborosos da tipicos da gastronomia japonesa: Pokes, temakis e mais.',
    menu: '',
    instagram: 'https://www.instagram.com/gendaioficial/',
  },
  {
    name: 'NÔMADE',
    img: nomade,
    address: 'Praça de Alimentação 3',
    description:
      'O Nômade é um Food truck gourmet que tem em seu menu Lanches, hamburguers e risotos.',
    menu: 'https://drive.google.com/file/d/1P1DETCCpt7mpBVW-_F--arsu2ApuoH03/view?usp=sharing',
    instagram: 'https://www.instagram.com/nomadefoodtruck/',
  },
  {
    name: 'PRIA SEHAT',
    img: priasehat,
    address: 'Praça de Alimentação 3',
    description:
      'Alimentação saudável e caseira fora de casa.</br> O Pria Sehat atende a veganos e vegetarianos com lanches e comidas saudáveis.',
    menu: 'https://drive.google.com/file/d/1KnUDmmzbddVBFWkZfMYF4afv4MWQQQYk/view?usp=sharing',
    instagram: 'https://www.instagram.com/sehat_pria/',
  },
  {
    name: 'SPOLETO',
    img: spoleto,
    address: 'Praça de Alimentação 1',
    description:
      'O italiano mais amado do Brasil. <br>O nome é uma homenagem à cidade de Spoleto, localizada na região de Úmbria na Itália e remete à ideia de descontração. </br>O Spoleto trabalha com massas frescas, saladas, carnes, risotos e sobremesas. </br> Você encontra pratos feitos, e também tem a opção de criar o seu, personalizando entre opções de massa, molhos e ingredientes.',
    menu: 'https://drive.google.com/file/d/1AsYUeiz8Jkx2piGHUNw9ncXqMRfi_K95/view?usp=sharing',
    instagram: 'https://www.instagram.com/spoleto_oficial/',
  },
  {
    name: 'TROPICAL SUCOS',
    img: tropicalsucos,
    address: 'Praça de Alimentação 2',
    description:
      'Focado em lanches e sucos naturais, o Tropical é a sua escolha para alimentação saudável e natural durante os eventos. </br> Nada melhor do que opções saudáveis durante a correria das feiras e eventos.',
    menu: 'https://drive.google.com/file/d/1NzgKCHTGg1ugjY3ylTjaEBD5vPI4ebSQ/view?usp=sharing',
    instagram: '',
  },
  {
    name: 'XPICANHA',
    img: xpicanha,
    address: 'Praça de Alimentação 2',
    description:
      'Desde 1995 o X Picanha Burguer & Grill traz para você deliciosos pratos. </br>No XPicanha você come deliciosos pratos, lanches e porções. </br>A especialidade da casa são os deliciosos hamburguers para você que é fanático por essa delícia. </br> Tudo feito com muito amor e recheio para acabar com sua fome!',
    menu: 'https://drive.google.com/file/d/1oVDlMpXB72dq8EAVCP9md_mLdvCRnh2X/view?usp=sharing',
    instagram: 'https://www.instagram.com/xpicanhaoficial/',
  },
];
