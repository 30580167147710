import React from "react";
//imgs
import tiktokSvg from '../../assets/images/vidCon/tiktok.svg';

export default function Sponsors(props) {
  function openLink(link) {
    window.open(link, '_blank');
  }
  return (
    <div className="div-sponsors" style={{ padding: '15px 10px' }}>
      <img
        alt="logo"
        rel="external"
        src={props.logo}
        className="img-sponsors"
        onClick={() => (props.url ? openLink(props.url) : '')}
      />

      {/* <a style={{ margin: '20px 0', textAlign: 'center' }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.fontSize: 25
      </a> */}

      <div className="div-icons-sponsors">
        {props.socialMedia.facebook && (
          <i
            className="mdi mdi-facebook"
            style={{ fontSize: 25 }}
            rel="external"
            onClick={() => openLink(props.socialMedia.facebook)}
          />
        )}
        {props.socialMedia.instagram && (
          <i
            rel="noopener noreferrer"
            className="mdi mdi-instagram"
            style={{ fontSize: 25, margin: '0 20px' }}
            onClick={() => openLink(props.socialMedia.instagram)}
          />
        )}
        {props.socialMedia.twitter && (
          <i
            className="mdi mdi-twitter"
            style={{ fontSize: 25 }}
            onClick={() => openLink(props.socialMedia.twitter)}
          />
        )}

        {props.socialMedia.tiktok && (
          <div style={{ marginTop: '7px', marginLeft: '15px' }}>
            <img
              src={tiktokSvg}
              alt="tiktok"
              width="24px"
              height="24px"
              onClick={() => openLink(props.socialMedia.tiktok)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
