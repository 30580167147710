import React, { useState, useEffect } from "react";
// utils
import { faqList } from "./utilsFaq";
import QuestionFaq from "./questionFaq";

export default function MenuFaq() {
  const [search, setSearch] = useState("");

  useEffect(() => {
    faqList.map((e, i) => {
      {
        if (
          e.question.filter(
            (quest) =>
              quest.title
                .toLocaleLowerCase()
                .indexOf(search.toLocaleLowerCase()) > -1
          ).length === 0
        ) {
          document.getElementById(e.title).style.display = "none";
        } else document.getElementById(e.title).style.display = "inline";
      }
    });
  }, [search]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="div-padding" style={{ backgroundColor: "white" }}>
        <input
          placeholder="Pesquise"
          className="search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {faqList.map((e, i) => (
        <div
          className="accordion div-sponsors"
          style={{ width: "100%", marginBottom: 20 }}
          id={e.title}
        >
          <h1 style={{ textAlign: "center" }}>{e.title}</h1>
          {e.question
            .filter(
              (quest) =>
                quest.title
                  .toLocaleLowerCase()
                  .indexOf(search.toLocaleLowerCase()) > -1
            )
            .map((quest, index) => (
              <QuestionFaq i={index} quest={quest} faqList={faqList[i]} />
            ))}
        </div>
      ))}
    </div>
  );
}
