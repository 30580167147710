import React, { useState, useEffect } from "react";
// componente
import OptionForAll from "./optionForAll/optionForAll";
import OptionIndustryProgramming from "./optionIndustryProgramming/optionIndustryProgramming";
import OptionCommunityProgramming from "./optionCommunityProgramming/optionCommunityProgramming";
import OptionMeetGreet from "./optionMeetGreet/optionMeetGreet";
// import SkeletonList from '../../../../components/skeleton/list';
import { LoadSpinner } from '../../../../components/LoadingSpinner';
// service
import { getAllItems } from '../../../../utils/services/index';
import * as Sentry from '@sentry/react';

export default function MenuType() {
  const [scheduleList, setScheduleList] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getItems() {
    await getAllItems()
      .then((res) => {
        if (res?.data?.response) {
          setScheduleList(res?.data?.response);
          setLoading(false);
        }
      })
      .catch((error) => {
        Sentry.setContext('response', error?.response?.data);
        Sentry.captureMessage('Request Get Items from MenuType');
      });
  }

  useEffect(() => {
    getItems();
  }, []);
  return (
    <div style={{ paddingTop: 50 }}>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '5%' }}>
        {loading ? (
          <div className="spinnerloading-container">
            <LoadSpinner />
          </div>
        ) : (
          // <SkeletonList count={6} />
          <>
            {/* <OptionForAll title={"Para todos"} /> */}
            <OptionIndustryProgramming
              title={'Programação Indústria'}
              scheduleList={scheduleList}
            />
            <OptionCommunityProgramming
              title={'Programação Comunidade'}
              scheduleList={scheduleList}
            />
            <OptionMeetGreet title={'Meet & Greet'} />
          </>
        )}
      </div>
    </div>
  );
}
