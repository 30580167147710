import React from "react";
import "./styled.css";
// img
import VidCon from "../../assets/images/vidCon/map.png";

export default function ModalInfo(props) {
  const { isOpen, toggle, data } = props;

  return (
    <div
      className="menu-home-mobile"
      style={{
        left: isOpen ? "0%" : "100%",
      }}
    >
      <i className="mdi mdi-chevron-left close-menu" onClick={toggle} />

      <i
        className="mdi mdi-share close-menu"
        style={{ right: 0, left: "auto" }}
      />

      <img src={VidCon} className="img-modal" />

      <div className="limit">
        <div className="div-title">{data.name ? <a>{data.name}</a> : null}</div>

        <a>Sobre</a>

        <div className="div-icons-sponsors">
          <i className="mdi mdi-facebook" style={{ fontSize: 30 }} />
          <i
            className="mdi mdi-instagram"
            style={{ fontSize: 30, margin: "0 20px" }}
          />
          <i className="mdi mdi-twitter" style={{ fontSize: 30 }} />
        </div>
      </div>
    </div>
  );
}
