import React, { useState } from "react";
import ModalInfoSpeakers from "./modalInfoSpeakers";

export default function Speackers(props) {
  const [modalSpeakers, setModalSpeakers] = useState(false);

  return (
    <>
      <ModalInfoSpeakers
        isOpen={modalSpeakers}
        toggle={() => setModalSpeakers(!modalSpeakers)}
        id={props.data.person.id}
      />
      <div
        className="div-description-schedule"
        style={{ justifyContent: "center" }}
        // onClick={() => console.log(props.data)}
        onClick={() => setModalSpeakers(true)}
      >
        {/* <img className="img-speackers" src={Profile} /> */}

        <div className="data-description-schedule">
          <a style={{ fontWeight: "bold", fontSize: 17 }}>
            {props.data.person.full_publication_name}
          </a>
        </div>
      </div>
    </>
  );
}
