import { Spinner } from 'reactstrap';

export const LoadSpinner = () => {
  const size = [0.6, 0.8, 1.0];
  return (
    <>
      {[...Array(3)].map((e, i) => (
        <Spinner
          key={i}
          style={{
            width: `${size[i]}rem`,
            height: `${size[i]}rem`,
            marginRight: '2px',
          }}
          type="grow"
        />
      ))}
    </>
  );
};
