import React, { useState } from "react";
import { Collapse } from "reactstrap";

export default function EventLocation() {
  const [sectionSubway, setSectionSubway] = useState(false);
  const [sectionApp, setSectionApp] = useState(false);
  const [sectionParking, setSectionParking] = useState(false);
  const [sectionBike, setSectionBike] = useState(false);
  return (
    <section
      className="section-align-center"
      style={{ justifyContent: "flex-start" }}
    >
      <div
        className="accordion"
        id="accordion"
        style={{ width: "100%", marginBottom: 20 }}
      >
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={`accordion-button fw-medium ${
                !sectionSubway && "collapsed"
              }`}
              type="button"
              onClick={() => setSectionSubway(!sectionSubway)}
              style={{ cursor: "pointer" }}
            >
              Vai de metrô?
            </button>
          </h2>

          <Collapse isOpen={sectionSubway} className="accordion-collapse">
            <div className="accordion-body">
              <div
                className="text-muted"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <a>As estações mais próximas são:</a>
                <a>
                  -Estação do Metrô Jabaquara (Linha Azul – Norte X Sul): 1,4 km
                  - 5 minutos.
                </a>
                <a>
                  -Estação do Metrô Santos-Imigrantes (Linha Verde – Paulista):
                  7,4 km - 20 minutos.
                </a>
              </div>
            </div>
          </Collapse>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className={`accordion-button fw-medium ${
                !sectionApp && "collapsed"
              }`}
              type="button"
              onClick={() => setSectionApp(!sectionApp)}
              style={{ cursor: "pointer" }}
            >
              Vai de táxi ou aplicativo de transporte?
            </button>
          </h2>

          <Collapse isOpen={sectionApp} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Tem um ponto de embarque e desembarque no São Paulo Expo -
                Centro de Convenções.
              </div>
            </div>
          </Collapse>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className={`accordion-button fw-medium ${
                !sectionParking && "collapsed"
              }`}
              type="button"
              onClick={() => setSectionParking(!sectionParking)}
              style={{ cursor: "pointer" }}
            >
              Vai de carro/moto?
            </button>
          </h2>
          <Collapse isOpen={sectionParking} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Tem um estacionamento no São Paulo Expo - Centro de Convenções,
                com 4.500 vagas no edifício garagem, conectado ao pavilhão da
                Vidcon São Paulo 2023 por meio de uma passarela. O
                estacionamento do São Paulo Expo não é vinculado ao evento,
                confira aqui a tabela de preço:{" "}
                <a
                  href="https://www.saopauloexpo.com.br/pt/estacionamento-visitante/"
                  target={"_blank"}
                >
                  Estacionamento – Visitante
                </a>
              </div>
            </div>
          </Collapse>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className={`accordion-button fw-medium ${
                !sectionBike && "collapsed"
              }`}
              type="button"
              onClick={() => setSectionBike(!sectionBike)}
              style={{ cursor: "pointer" }}
            >
              Vai de bike?
            </button>
          </h2>
          <Collapse isOpen={sectionBike} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-muted">
                Tem um estacionamento no São Paulo Expo - Centro de Convenções
                com 430 vagas gratuitas no bicicletário (
                <a
                  href="https://www.saopauloexpo.com.br/pt/estacionamento-visitante/"
                  target={"_blank"}
                >
                  Estacionamento – Visitante
                </a>
                ).
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.836931825124!2d-46.634141075334966!3d-23.646010235514748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5adda4c3cb95%3A0x31916ce58f719167!2sS%C3%A3o%20Paulo%20Expo!5e0!3m2!1spt-BR!2sbr!4v1679943533138!5m2!1spt-BR!2sbr"
        style={{ width: "100%", height: "500px" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
    </section>
  );
}
