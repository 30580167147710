import React, { useState, useEffect } from 'react';
// nomes na listagem
import { exhibitorsSchedulle } from '../../utils/ExhibitorsSchedulle/index';
// componentes
import ModalInfo from '../../components/modalInfo/modalInfo';

export default function MenuPartyLounge() {
  const [search, setSearch] = useState('');
  const [dataSchedule, setDataSchedule] = useState([]);
  // modal
  const [modalInfo, setModalInfo] = useState(false);
  const [dataModalInfo, setDataModalInfo] = useState({
    name: '',
    img: '',
  });

  function openLink(link) {
    window.open(link, '_blank');
  }

  useEffect(() => {
    let data = exhibitorsSchedulle
      .filter((e) => e.name.toLocaleLowerCase().indexOf(search) > -1)
      .reduce((r, e) => {
        let group = e.name[0].toLocaleUpperCase();
        if (!r[group]) r[group] = { group, children: [e] };
        else r[group].children.push(e);
        return r;
      }, {});

    setDataSchedule(Object.values(data));
  }, [search]);

  return (
    <div className="list-itens-column">
      <ModalInfo
        isOpen={modalInfo}
        toggle={() => setModalInfo(!modalInfo)}
        data={dataModalInfo}
      />
      <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 20 }}>
        Meet & Greet
      </p>
      <p style={{ fontWeight: 'bold', fontSize: 14 }}>
        CONHEÇA SEU CREATOR FAVORITO!
      </p>
      <div className="div-padding" style={{ padding: '0 5%', marginTop: 20 }}>
        <p>
          A VidCon São Paulo oferecerá aos fãs a oportunidade de conhecer
          algumas das maiores estrelas da internet.
        </p>

        <p>
          O site para agendamento das sessões de Meet & Greet está aberto
          durante os dias 6 a 20 de junho exclusivamente para quem comprou o
          ingresso Comunidade 2 Dias e/ou Indústria. A partir do dia 21, quem
          comprou ingresso para um dia de evento (sábado ou domingo) também
          poderá fazer seu login no site e agendar uma sessão de Meet & Greet. O
          site permanecerá aberto até o último dia do evento (9 de julho) ou até
          que as sessões se esgotem.{' '}
          <span style={{ fontWeight: 'bold' }}>
            Cada portador de um ingresso para a VidCon São Paulo 2023 pode
            agendar uma sessão de Meet & Greet.
          </span>{' '}
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>Importante:</span> todas as
          sessões de Meet & Greet tem um número de vagas limitadas e poderão ser
          agendadas por ordem de chegada e conforme disponibilidade. A
          disponibilidade de vagas para as sessões não é garantida e a compra de
          um ingresso não garante o acesso ao Meet & Greet.
        </p>
        <button
          className="btn-map-exhibitors"
          onClick={() => openLink('https://godream.com.br/')}
        >
          Agende Sua Sessão
        </button>
        <p style={{ marginTop: '24px' }}>
          Precisa de ajuda para agendar sua sessão? Confira{' '}
          <a
            target="_blank"
            href="https://www.vidcon.com/sao-paulo/pt/faq/how-can-i-sign-up-to-participate-in-meet-greets-at-vidcon-sao-paulo/"
            rel="noreferrer"
          >
            aqui{' '}
          </a>
          um tutorial detalhado!
        </p>

        <p
          style={{
            fontWeight: 'bold',
            fontSize: 14,
            marginTop: '24px',
            textAlign: 'center',
          }}
        >
          JÁ AGENDEI MINHA SESSÃO, E AGORA?
        </p>
        <p>
          Apresente o QR Code do agendamento na entrada da sessão. Você deve se
          direcionar à fila da sua respectiva sala até 1 hora antes do início da
          sua sessão. Temos uma tolerância de até 15 minutos após o horário de
          início, se você chegar depois desse tempo limite de tolerância, você
          não poderá entrar e participar da sessão de Meet & Greet.
        </p>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: 14,
            marginTop: '24px',
            textAlign: 'center',
          }}
        >
          COMO SERÁ A SESSÃO DE MEET & GREET?
        </p>
        <p>
          Você poderá ver o seu creator preferido pessoalmente e tirar uma
          selfie com ele! Não teremos fotos profissionais, por isso mantenha o
          seu celular à postos para registrar esse momento único.{' '}
        </p>
        <p>
          Ficou com alguma outra dúvida? Confira a sessão Meet & Greet da nossa{' '}
          <a
            target="_blank"
            href="https://www.vidcon.com/sao-paulo/pt/faq/category/meet-greets-pt/"
            rel="noreferrer"
          >
            FAQ
          </a>{' '}
          ou mande um e-mail para saopauloinfo@vidcon.com.
        </p>
      </div>
    </div>
    // <div className="center-img" style={{ justifyContent: "center" }}>
    //   <a style={{ textAlign: "center" }}>Em breve</a>
    // </div>
  );
}
