import React, { useState, useEffect } from "react";
// componente
import OptionWhereSchedule from "./optionWhereSchedule";
// import SkeletonList from "../../../../components/skeleton/list";

export default function MenuWhere(props) {
  return (
    <div style={{ paddingTop: 50 }}>
      {/* <div className="div-padding" style={{ backgroundColor: "white" }}>
        <input placeholder="Pesquise" className="search" />
      </div> */}
      {props?.loading ? (
        <div style={{ paddingTop: 50 }}>
          <>{/* <SkeletonList count={7} /> */}</>
        </div>
      ) : (
        <>
          <div
            style={{ display: 'flex', flexDirection: 'column', padding: '5%' }}
          >
            {props?.roomsData?.map((e, i) => (
              <OptionWhereSchedule
                data={e}
                scheduleList={props?.scheduleList}
                key={i}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
